.signUpWrapper {
  padding-bottom: 60px;
  .companyLogo {
    width: 200px;
    height: 200px;

    @media only screen and (max-width: 600px) {
      width: 35%;
      height: 120px;
    }
  }

  .signUpContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 80px;
    width: 90%;
    margin: auto;

    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      gap: 40px;
    }

    @media only screen and (max-width: 600px) {
      gap: 30px;
    }

    .signUpForm {
      border-radius: 27px;
      opacity: 1;
      background-color: rgba(238, 251, 255, 1);
      padding: 72px 67px 87px;

      @media only screen and (min-width: 1600px) {
        width: 50%;
      }

      @media only screen and (max-width: 600px) {
        padding: 40px 12px;
      }

      h2 {
        opacity: 1;
        color: rgba(31, 31, 31, 1);
        font-family: "HelveticaNeue-Medium";
        font-size: 32px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0px;
        text-align: center;
        margin-bottom: 36px;

        @media only screen and (max-width: 600px) {
          width: 70%;
          margin: auto;
          margin-bottom: 36px;
        }
      }

      .formWrapper {
        .passwordRequire {
          p {
            opacity: 1;
            color: rgba(73, 73, 73, 1);
            font-family: "HelveticaNeue";
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
            text-align: left;
            margin: 0;
            margin-left: 40px;
          }

          ul {
            margin-bottom: 20px;
            li {
              opacity: 1;
              color: rgba(73, 73, 73, 1);
              font-family: "HelveticaNeue";
              font-size: 14px;
              font-weight: 400;
              font-style: normal;
              letter-spacing: 0px;
              text-align: left;
              margin: 0;
              margin-left: 40px;
            }
          }

          @media only screen and (max-width: 600px) {
            display: none;
          }
        }

        .passwordRequireMob {
          display: none;

          p {
            opacity: 1;
            color: #7a7e82;
            font-family: "HelveticaNeue";
            font-size: 12px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
            text-align: center;
            margin: 0;
            margin-bottom: 10px;
          }

          @media only screen and (max-width: 600px) {
            display: block;
            margin-top: -10px;
          }
        }

        .TextInputContainer {
          input {
            &::placeholder {
              color: #9c9c9c !important;
            }
          }
        }

        .privacyTermsLink {
          margin: 10px 0 36px;

          @media only screen and (max-width: 600px) {
            display: none;
          }

          p {
            opacity: 1;
            color: rgba(73, 73, 73, 1);
            font-family: "HelveticaNeue";
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
            text-align: center;
            margin: 0;
          }

          a {
            opacity: 1;
            color: #61849d;
            font-family: "HelveticaNeue";
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
            text-decoration: underline;
          }
        }

        .privacyTermsLinkMobile {
          display: none;
          margin: 10px 0 20px;

          @media only screen and (max-width: 600px) {
            display: block;
          }

          p {
            opacity: 1;
            color: rgba(73, 73, 73, 1);
            font-family: "HelveticaNeue";
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
            text-align: center;
            margin: 0;
          }

          a {
            opacity: 1;
            color: #61849d;
            font-family: "HelveticaNeue";
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
            text-decoration: underline;
          }
        }

        .buttonSubmit {
          text-align: center;
          margin-bottom: 29px;
          button {
            border-radius: 23px;
            box-shadow: 0px 2px 4px rgba(217, 217, 217, 1);
            opacity: 1;
            background-color: rgba(97, 132, 157, 1);
            width: 320px;
            height: 48px;
            border: none;
            opacity: 1;
            color: rgba(255, 255, 255, 1);
            font-family: "HelveticaNeue-Medium";
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: 0px;
            text-align: left;
            text-align: center;

            @media only screen and (max-width: 600px) {
              width: 100%;
            }
          }
        }

        .haveAcc {
          text-align: center;
          a {
            opacity: 1;
            color: rgba(156, 156, 156, 1);
            font-family: "HelveticaNeue-Medium";
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: 0px;
            text-decoration: underline;
            margin: 0;
          }
        }
      }
    }

    .signUpTestimonials {
      width: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      margin: 20px 0 20px -9px;

      @media only screen and (min-width: 1600px) {
        width: 50%;
      }

      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 30px;
        margin: 20px 0;
      }

      h2 {
        opacity: 1;
        color: #494949;
        font-family: "HelveticaNeue-Medium";
        font-size: 32px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        display: none;

        @media only screen and (max-width: 1024px) {
          display: block;
          font-size: 18px;
          font-weight: 500;
        }
      }

      .testimonialContent {
        width: 100%;
        // width: 80%;
        border-radius: 22px;
        box-shadow: 0px 4px 14px rgba(97, 132, 157, 0.156332);
        opacity: 1;
        background-color: rgba(255, 255, 255, 1);
        padding: 51px 40px 30px;
        position: relative;

        img {
          width: auto;
        }

        @media only screen and (max-width: 600px) {
          padding: 51px 25px 30px;
        }

        .quote {
          opacity: 1;
          position: absolute;
          top: -20px;
          left: 40px;
        }

        P {
          margin: 0;
          opacity: 1;
          color: rgba(73, 73, 73, 1);
          font-family: "HelveticaNeue";
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
          line-height: 18px;
        }

        span {
          display: block;
          border-bottom: 1px solid #e2e2e2;
          height: 1px;
          margin: 25px 0;
        }

        .testimonialName {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 20px;

          img {
            width: 52px;
            height: 52px;
            border-radius: 50%;
          }

          h3 {
            opacity: 1;
            color: rgba(152, 152, 152, 1);
            font-family: "HelveticaNeue";
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
            text-align: left;
            margin-bottom: 0;
          }
        }
      }
    }

    .carousel-status,
    .carousel .thumbs-wrapper,
    .carousel .control-dots {
      display: none;
    }
  }

  .testimonial-name {
    color: #494949;
    font-size: 14px;
  }

  .testimonial-job {
    color: #9c9c9c;
    font-size: 12px;
  }
}
