.mainContainerHeaderWrapper {
  padding: 0 30px;

  @media only screen and (min-width: 1200px) {
    padding: 0 20px;
  }
}

.mainContainerWrapper {
  padding: 0 30px;

  @media only screen and (min-width: 1200px) {
    padding: 0 120px;
  }
}

.mainContainerBanerWrapper {
  padding: 0 30px;

  @media only screen and (min-width: 1200px) {
    padding-left: 120px;
    padding-right: 40px;
    max-width: 1440px;
    margin: auto;
  }
}

.amazingWrapperContainer {
  padding: 0 30px;

  @media only screen and (min-width: 1200px) {
    padding-left: 180px;
    padding-right: 180px;
    max-width: 1440px;
    margin: auto;
  }
}

.benefitsContainerWrapper {
  padding: 0 30px;

  @media only screen and (min-width: 1200px) {
    padding-left: 104px;
    padding-right: 120px;
  }
}

.growContainerWrapper {
  padding: 0 30px;

  @media only screen and (min-width: 1200px) {
    padding-left: 70px;
    padding-right: 70px;
    max-width: 1440px;
    margin: auto;
  }
}

.measureContaienrWrap {
  padding: 0 30px;

  @media only screen and (min-width: 1200px) {
    padding-left: 120px;
    padding-right: 130px;
  }
}

.videoContainerWrapp {
  padding: 0 30px;

  @media only screen and (min-width: 1200px) {
    padding-left: 180px;
    padding-right: 180px;
    max-width: 1440px;
    margin: auto;
  }
}

.testimonialContainerWrapper {
  padding: 0 30px;

  @media only screen and (min-width: 1200px) {
    padding-left: 65px;
    padding-right: 65px;
    max-width: 1440px;
    margin: auto;
  }
}

.contactContainerWrap {
  padding: 0 30px;

  @media only screen and (min-width: 1200px) {
    padding-left: 120px;
    padding-right: 180px;
  }
}

// Baner
.banerWrap {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media only screen and (min-width: 1200px) {
    flex-direction: row;
    padding-top: 10px;
    // padding-bottom: 130px;
    gap: 53px;
  }

  .leftContect {
    @media only screen and (min-width: 1200px) {
      margin-top: -20px;
    }
    h2 {
      margin-bottom: 16px;
      width: 100%;

      @media only screen and (min-width: 1200px) {
        width: 540px;
      }
    }

    p {
      margin-bottom: 40px;
      font-family: "HelveticaNeue";
      font-size: 18px;

      @media only screen and (min-width: 1200px) {
        width: 85%;
      }
    }
  }

  .rightContent {
    .glow {
      opacity: 1;
      background-image: radial-gradient(
        rgb(94, 147, 186) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      filter: blur(23.811966px);
      position: absolute;
      width: 70%;
      height: 70%;
      top: 7%;
      left: 15%;
    }
  }
}

.banerWrapperSection {
  margin-top: 20px !important;

  @media only screen and (min-width: 1200px) {
    margin-top: 80px;
  }
}

// Amazing Results
.amazingWrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding-top: 90px;
  padding-bottom: 90px;

  @media only screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 75px;
    padding-top: 0;
    padding-bottom: 130px;
  }

  .leftContent {
    width: 100%;
    position: relative;

    @media only screen and (min-width: 1200px) {
      width: 466px;
    }

    .glow3 {
      opacity: 1;
      background-image: radial-gradient(
        rgba(94, 147, 186, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      filter: blur(23.811966px);
      position: absolute;
      top: -3%;
      width: 100%;
      left: 20px;
    }

    img {
      width: 100%;
      z-index: 2;
      position: relative;
      @media only screen and (min-width: 1200px) {
        width: 466px;
      }
    }
  }

  .rightContent {
    display: flex;
    flex-direction: column;

    gap: 16px;
    p {
      margin: 0;
    }
  }
}

// Benefits
.benefitsWrap {
  padding-top: 57px;
  padding-bottom: 57px;
  background: linear-gradient(270deg, #b3d2e9 0%, #cfe3f1 25%, #e7f1f8 100%);
  text-align: center;

  @media only screen and (min-width: 1200px) {
    padding-top: 86px;
    padding-bottom: 130px;
  }

  .benefitsWidth {
    @media only screen and (min-width: 1200px) {
      max-width: 1440px;
      margin: auto;
    }

    .videoContainer {
      width: 100%;
      height: 200px;
      margin-bottom: 0;

      @media only screen and (min-width: 700px) {
        height: 350px;
      }

      @media only screen and (min-width: 1200px) {
        height: 500px;
      }

      .videoClip {
        width: 100% !important;
        height: 200px !important;

        @media only screen and (min-width: 700px) {
          height: 350px !important;
        }

        @media only screen and (min-width: 1200px) {
          height: 500px !important;
        }
      }
    }
  }

  .topContent {
    width: 100%;
    margin: auto;
    padding-bottom: 90px;

    @media only screen and (min-width: 1200px) {
      width: 600px;
      padding-bottom: 80px;
    }

    p {
      &:nth-child(1) {
        padding-bottom: 16px;
      }

      &:nth-child(2) {
        width: 100%;
        margin: auto !important;

        @media only screen and (min-width: 1200px) {
          width: 480px;
        }
      }
    }
  }

  .dropdownListContent {
    display: flex;
    justify-content: center;
    gap: 60px;
    flex-direction: row;
    width: 100%;
    margin-bottom: 80px;

    @media only screen and (max-width: 992px) {
      flex-direction: column;
      gap: 0;
      margin-top: -40px;
      margin-bottom: 40px;
      align-items: center;
    }

    .landing-panel {
      margin-right: 0px;

      .landing-panel-body-text {
        text-align: left;
      }
    }
  }

  .benefitVideo {
    display: flex;
    justify-content: center;
    width: 85%;
    margin: auto;

    @media only screen and (max-width: 992px) {
      width: 100%;
    }

    .videoContainer {
      width: 100%;
      height: auto;
      margin-bottom: 0;

      .videoClip {
        width: 100% !important;
        height: auto !important;
      }
    }
  }

  .bottomContent {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    @media only screen and (min-width: 1200px) {
      flex-direction: row;
      gap: 70px;
    }

    .leftContent {
      img {
        display: none;
        width: 680px;
        margin: 0;

        @media only screen and (min-width: 1200px) {
          display: block;
        }
      }
    }

    .rightContent {
      @media only screen and (min-width: 1200px) {
        width: 545px;
      }
      .landing-panel {
        padding-right: 0;
        width: 100%;

        @media only screen and (min-width: 1200px) {
          width: 100%;
        }
      }

      .landing-panel-body-text {
        text-align: left;
      }
    }
  }
}

// Grow
.growWrapp {
  padding-top: 57px;
  padding-bottom: 57px;

  @media only screen and (min-width: 1200px) {
    padding-top: 86px;
    padding-bottom: 86px;
  }

  .growContent {
    background-color: #f5f5f5;
    padding: 46px 19px 61px;
    border-radius: 38px;

    @media only screen and (min-width: 1200px) {
      padding: 57px 75px 64px;
    }

    .topContent {
      padding-bottom: 60px;
    }

    .middleContent {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 40px;
      gap: 48px;

      @media only screen and (min-width: 1200px) {
        gap: 0;
      }

      div {
        text-align: center;
        width: 100%;

        @media only screen and (min-width: 1200px) {
          width: fit-content;
        }
      }
    }

    .bottomContent {
      text-align: center;

      button {
        color: #ffffff;
        width: 241px !important;
        height: 50px !important;
        background-color: #5e93ba !important;
        border-radius: 10px;

        &:hover {
          color: #232323;
        }
      }
    }
  }
}

// Measure
.measureWrap {
  display: flex;
  flex-direction: column;
  padding-top: 18px;
  padding-bottom: 57px;
  background: linear-gradient(90deg, #e7f1f8 100%, #cfe3f1 25%, #b3d2e9 0%);

  @media only screen and (min-width: 1200px) {
    padding-top: 86px !important;
    padding-bottom: 86px !important;
  }

  .measureWidth {
    @media only screen and (min-width: 1200px) {
      max-width: 1440px;
      margin: auto;
      flex-direction: row;
      display: flex;
      align-items: flex-start;
      padding-top: 81px;
      padding-bottom: 109px;
      gap: 92px;
    }
  }

  .landing-section-five-text-title,
  .landing-section-five-text-body {
    width: 100%;

    @media only screen and (min-width: 1200px) {
      width: 590px;
    }
  }

  .landing-laptop-foto {
    width: 100%;
    object-fit: cover;

    @media only screen and (min-width: 1200px) {
      width: auto;
    }
  }

  .readMoreBtn {
    opacity: 1;
    color: rgba(97, 132, 157, 1);
    font-family: "HelveticaNeue-Medium";
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 19px;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 20px;

    @media only screen and (min-width: 1200px) {
      margin-top: 00px;
    }
  }

  @media only screen and (min-width: 1200px) {
    flex-direction: row;
    align-items: flex-start;
    padding-top: 81px;
    padding-bottom: 109px;
    gap: 92px;
  }
}

// Video
.videoWrap {
  padding-top: 57px;
  padding-bottom: 57px;

  @media only screen and (min-width: 1200px) {
    padding-top: 86px;
    padding-bottom: 130px;
  }

  .topContent {
    text-align: center;
    padding-bottom: 50px;
    width: 100%;
    margin: auto;

    @media only screen and (min-width: 1200px) {
      width: 969px;
    }

    p {
      line-height: 41px;
    }
  }

  .bottomContent {
    .videoContainer {
      width: 100%;
      height: 200px;
      margin-bottom: 0;

      @media only screen and (min-width: 700px) {
        height: 350px;
      }

      @media only screen and (min-width: 1200px) {
        height: 500px;
      }

      .videoClip {
        width: 100% !important;
        height: 200px !important;

        @media only screen and (min-width: 700px) {
          height: 350px !important;
        }

        @media only screen and (min-width: 1200px) {
          height: 500px !important;
        }
      }
    }
  }
}

// Testimonials
.testimonialWrap {
  padding-bottom: 57px;

  @media only screen and (min-width: 1200px) {
    padding-bottom: 86px;
  }

  .topContent {
    text-align: center;
    padding-bottom: 55px;
  }

  .middleContent {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 30px;
    padding-bottom: 32px;

    .info {
      background: #f5f5f5;
      padding: 30px;
      min-height: 438px;

      @media only screen and (min-width: 1200px) {
        padding: 35px;
      }

      .imageInfo {
        width: 90px;
        height: 90px;
        display: inline-block;
        width: auto;
        padding: 0;
        border-radius: 50%;
      }

      .textInfo {
        width: 100%;

        @media only screen and (min-width: 1200px) {
          width: 360px;
        }
      }

      .infoTopWrap {
        margin-bottom: 21px;
        gap: 21px;

        @media only screen and (min-width: 1200px) {
          margin-bottom: 32px;
          display: flex;
          align-items: center;
          gap: 24px;
        }
      }

      .landing-section-seven-item-top {
        margin-bottom: 32px;
      }
    }
  }

  .bottomContent {
    text-align: center;
  }
}

.testimonialsSlide {
  position: relative;
  width: 100%;
  overflow: hidden;

  .testimonialsContainer {
    display: flex;
    transition: transform 0.5s ease;
    width: calc(100% + #{20px * 2}); // Adding gap space on both sides
    gap: 20px; // Adjust the gap value as needed
  }

  .slideBtns {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
    margin-top: 32px;

    @media (max-width: 992px) {
      margin-top: 10px;
      gap: 14px;
    }

    img {
      cursor: pointer;
    }
  }
}

.testimonialCardWrapper {
  min-width: calc((100% / 3) - 20px);
  box-sizing: border-box;
  padding: 35px 25px 20px 20px;
  border-radius: 5px;
  opacity: 1;
  background-color: rgba(245, 245, 245, 1);
  height: 424px;

  @media (max-width: 992px) {
    min-width: calc((100% / 2) - 20px);
    padding: 10px 20px 10px 10px;
    height: auto;
  }

  @media (max-width: 768px) {
    min-width: calc(100% - 20px);
    padding: 35px 20px 17px 10px;
  }

  .readMoreBtn {
    background-color: transparent;
    border: none;
    box-shadow: none;

    opacity: 1;
    color: rgba(97, 132, 157, 1);
    font-family: "HelveticaNeueMedium";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
    line-height: 19px;
    text-transform: uppercase;
    text-decoration: underline;
    padding: 0;
  }

  .topCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    margin-bottom: 24px;

    @media (max-width: 992px) {
      flex-direction: column;
      align-items: baseline;
      gap: 10px;
      margin-bottom: 17px;
    }

    img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
    }

    .topCardName {
      h3 {
        opacity: 1;
        color: rgba(31, 31, 31, 1);
        font-family: "HelveticaNeueRegular";
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: left;

        @media (max-width: 992px) {
          font-size: 18px;
        }
      }

      h5 {
        opacity: 1;
        color: rgba(97, 132, 157, 1);
        font-family: "HelveticaNeueMedium";
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.35px;
        text-align: left;

        @media (max-width: 992px) {
          font-size: 14px;
        }
      }
    }
  }

  p {
    opacity: 1;
    color: rgba(31, 31, 31, 1);
    font-family: "HelveticaNeueLight";
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 10px;

    @media (max-width: 992px) {
      width: 95%;
    }
  }
}

.testimonialOverlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(97, 132, 157, 0.6);
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(10px);

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.testimonialModelContent {
  position: relative;
  width: 800px;
  padding: 50px 60px;
  background-color: rgba(238, 251, 255, 1);

  border-radius: 20px;
  text-align: center;

  @media (max-width: 992px) {
    width: 85%;
    padding: 35px 20px;
  }

  .closeIcon {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;

    @media (max-width: 992px) {
      right: 20px;
      top: 20px;
    }
  }

  .profile {
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }

  h3 {
    opacity: 1;
    color: rgba(31, 31, 31, 1);
    font-family: "HelveticaNeueRegular";
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 10px;

    @media (max-width: 992px) {
      font-size: 18px;
    }
  }

  h5 {
    opacity: 1;
    color: rgba(97, 132, 157, 1);
    font-family: "HelveticaNeueMedium";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.35px;
    text-align: center;
    margin-bottom: 32px;

    @media (max-width: 992px) {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  p {
    opacity: 1;
    color: rgba(31, 31, 31, 1);
    font-family: "HelveticaNeueLight";
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0px;
    text-align: left;
    // max-height: 300px;
    // overflow: auto;

    @media (max-width: 992px) {
      max-height: 300px;
      overflow: auto;
    }
  }
}

// Contact
.contactWrap {
  padding-top: 50px;
  padding-bottom: 50px;
  background: linear-gradient(270deg, #b3d2e9 0%, #cfe3f1 25%, #e7f1f8 100%);
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1200px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  @media only screen and (min-width: 1200px) {
    .contactWidth {
      // max-width: 1440px;
      // margin: auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 300px;
    }
  }

  .leftContent {
    width: 100%;
    text-align: center;

    p {
      width: 100%;

      @media only screen and (min-width: 1200px) {
        width: 400px;
      }
    }

    .landing-section-eight-left-body {
      margin-bottom: 0;
    }

    @media only screen and (min-width: 1200px) {
      width: 360px;
      text-align: center;
    }
  }

  .rightContent {
    .TextInputContainer {
      textarea {
        width: 100%;
      }
    }

    .landing-section-eight-button-margin {
      margin-bottom: 57px;
      margin-top: 30px;

      @media only screen and (min-width: 1200px) {
        margin-bottom: 0;
        margin-top: 30px;
      }
    }
  }
}

// Footer
.footerWrapper {
  background-color: #f5f5f5;
  padding-top: 56px;
  padding-bottom: 16px;

  @media only screen and (min-width: 1200px) {
    padding-top: 38px;
    padding-bottom: 12px;
  }

  .topArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 63px;
    border-bottom: 1px solid #ded6c7;

    @media only screen and (min-width: 1200px) {
      padding-bottom: 47px;
    }

    .footerLogo {
      width: 90px;
      height: 33px;

      @media only screen and (min-width: 1200px) {
        width: 134px;
        height: 49px;
      }
    }

    .footerSocial {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  .bottomArea {
    color: rgba(156, 156, 156, 1);
    padding-top: 7px;
    text-align: center;

    a {
      opacity: 1;
      color: rgba(156, 156, 156, 1);
      font-family: "HelveticaNeue";
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0px;
      text-align: left;
      line-height: 26px;
      text-decoration: none;
    }
  }
}
