.FullStepper {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 58px;
  left: 328px;
  right: 80px;
  margin: 0 auto;
  overflow: visible;
}

.fullStepperLine {
  position: absolute;
  width: calc(100% - 86px);
  top: 16px;
  height: 20px;
  background-color: transparent;
  border-top: 3px dashed #ded6c7;
  z-index: 1;
  margin-left: 38px;
}

@media (max-width: 992px) {
  // mobile

  .FullStepper {
    top: 73px;
    left: 0;
    right: 0;
    width: calc(100vw - 64px);
  }

  .fullStepperLine {
    margin-left: -30px;
    width: 100vw;
  }
}

@media (max-width: 670px) {
  // mobile

  // .FullStepper {
  //   display: none;
  // }
}

@media only screen and (max-width: 600px) {
  .FullStepper {
    display: flex;
    top: 110px;
    position: relative;

    .stepperText {
      display: none;
    }
  }
}

@media only screen and (min-width: 642px) and (max-width: 992px) {
  .FullStepper {
    display: flex;
    top: 170px;
    position: relative;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .ThirdScreenSurvey {
    margin-top: 100px;
  }
}
