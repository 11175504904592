.Button {
  width: 100%;
  height: 100%;
  border-radius: 23px;
  box-shadow: 0px 2px 4px rgba(217, 217, 217, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
}

.buttonText {
  color: white;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  padding: 0 5px;
}
