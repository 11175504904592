.dropdownMain {
  width: 100%;
  height: 40px;
  position: relative;

}

.dropdownClosed {
  border-radius: 23px;
  background-color: white;
  border: 1px solid #61849d;
  height: 40px;
  position: relative;
  cursor: pointer;
}

.dropdownOpen {
  width: 100%;
  background-color: white;
  padding: 10px 10px;
  border: 1px solid #61849d;
  border-radius: 23px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;

  max-height: 150px;
  overflow: auto;
}

.dropdownOption {
  width: calc(100% - 12px);
  color: #494949;
  font-size: 14px;
  padding-left: 12px;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
}

.dropdownOption:hover {
  background-color: #ecfaff;
}

.dropdownPlaceholder {
  color: #9c9c9c;
  font-size: 14px;
  padding-left: 12px;
  height: 32px;
}

.dropdownArrow {
  position: absolute;
  right: 17px;
  top: 17px;
}

.dropdownArrowOpen {
  transform: rotate(180deg);
}

.selectedOption {
  color: #494949;
  font-size: 14px;
  margin-left: 24px;
  margin-top: 10px;
}

.selectedOptionPlaceholder {
  color: #9c9c9c;
  font-size: 14px;
  margin-left: 24px;
  margin-top: 10px;
}

.dropdownLabel {
  color: #494949;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  margin-left: 24px;
  margin-bottom: 8px;
  min-width: 173px;
}

.noPractitioner {
  color: #9c9c9c;
}

.practitionerName {
  color: #1f1f1f;
}

.selectPractitioner {
  cursor: pointer;
  display: flex;
  gap: 12px;
}

.selectPractitionerImage {
  transform: rotate(270deg);
}

.selectPractitioner:hover {
  text-decoration: underline;
}

.dropdownClosed.dropdownPractitioner {
  border: none !important;
  background: unset !important;
}

.dropdownArrow.dropdownPractitioner {
  top: 8px !important;
  left: 0px !important;
}

.selectedOptionPlaceholder.dropdownPractitioner {
  margin-top: 17px !important;
  margin-left: 20 !important;
}

.selectedOption.dropdownPractitioner {
  margin-left: 0 !important;
  padding-left: 20px;
}

.dropdownArrowOpen.dropdownPractitioner {
  left: 10px !important;
  right: unset !important;
  top: 17px !important;
}

.dropdownOption.dropdownPractitioner {
  padding-left: 20px !important;
}

.dropdownPlaceholder.dropdownPractitioner {
  padding-left: 20px !important;
}

.dropdownLabel.dropdownBulk {
  min-width: 200px !important;
}

.showDropdownListTitle {
  display: flex;
  flex-direction: column;
  grid-area: 10px;

  h3 {
    opacity: 1;
    color: rgba(156, 156, 156, 1);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    padding-left: 12px;
    margin: 0;
  }

  p {
    color: #494949;
    font-size: 14px;
    padding-left: 12px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    justify-content: start;
    align-items: center;
  }
}
