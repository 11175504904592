.TextInput {
  background-color: white;
  border-radius: 24px;
  width: 100%;
  height: 48px;
  padding-left: 33px;
  padding-right: 33px;
  font-size: 14px !important;
  margin-top: 8px;
  border: 1px solid white;
  color: #494949;
}

.LandingInput {
  border-radius: 10px !important;
}

input::placeholder {
  color: #9c9c9c !important;
  font-size: 14px;
  opacity: 1 !important;
}

.TextInput:focus-within {
  //border: 1px solid #61849D !important;
}

.passwordIcon {
  cursor: pointer;
  position: absolute;
  margin-top: 20px;
  margin-left: -40px;
}

.TextInput:before {
  border-bottom: none !important;
}

.TextInput:after {
  border-bottom: none !important;
}

.errorMessage {
  color: red;
  font-size: 12px;
  text-align: center;
  width: 544px;
  margin-top: 5px;
  height: 14.5px;
}

.explanation{
  color: #9C9C9C;
   font-size: 12px;
  width: 544px;
  margin-top: 5px;
  height: 15px;
  margin-left: 30px;
  margin-bottom: 20px;

   @media only screen and (max-width: 992px){
    margin-left: 0;
    font-size: 10px;
   }


}

.TextInputContainer {
  @media only screen and (max-width: 992px) {
    text-align: center;
  }
}

.ConfirmPopUpContent {
  @media only screen and (max-width: 992px) {
    width: 100%;
    align-items: center;
  }

  @media only screen and (max-width: 600px) {
    width: auto;
    align-items: center;
  }
}

.ConfirmPopUpTitle {
  @media only screen and (max-width: 992px) {
    margin-bottom: 0;
  }
}

.ConfirmPopUpBox {
  @media only screen and (max-width: 992px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 150px;
  }

  @media only screen and (max-width: 380px) {
    margin-top: 100px;
  }
}

textarea {
  height: 106px !important;
  background-color: white;
  border-radius: 24px;
  width: 100%;
  padding-left: 33px;
  padding-right: 33px;
  font-size: 14px !important;
  margin-top: 8px !important;
  border: 1px solid white;
  padding-top: 17px;
  font-family: "HelveticaNeueMedium", serif !important;
  color: #494949;
  text-size-adjust: none !important;
  font-style: normal !important;
  resize: none !important;
}


textarea.smallTextSurveyInput {
  height: 50px !important;
}

textarea::placeholder {
  color: #9c9c9c !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-style: normal !important;
}

textarea:focus {
  outline: none !important;
}

@media screen and (max-width: 992px) {
  .TextInput {
    width: 100% !important;
  }

  textarea {
    // height: 200px !important;
    width: 90%;
  }

  .errorMessage {
    // width: calc(100vw - 64px);
    width: 100%;
  }

  .explanation{
     width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .ConfirmPopUpContent {
    textarea {
      width: 80%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .ConfirmPopUp {
    position: fixed !important;
    overflow: hidden !important;
    top: 0 !important;
  }
}
