.FirstScreenSurvey {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  @media only screen and (max-width: 600px) {
    height: 100%;
  }

  .videoContainer {
    @media only screen and (max-width: 600px) {
      margin-top: 100px;
    }
  }
}

.centeredDiv {
  display: flex;
  width: 100%;
  justify-content: space-evenly;

  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}

.text-only {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.left-text {
  align-items: start;
}

.helloDiv {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  text-align: center;
  font-size: 16px;
}

.headline {
  margin-top: 30px;
  text-align: center;
  font-size: 56px;
  opacity: 1;
  color: #1f1f1f;
  font-weight: 400;
  font-family: NewAthenaRegular, serif;
}

.text {
  margin-top: 27px;
  font-size: 16px;
  font-weight: 400;
  width: 480px;
  text-align: center;
}

.text-left {
  text-align: left;
}

.ctaContainer {
  width: 240px;
  height: 45px;
  margin-top: 50px;
}

@media (max-width: 641px) {
  // mobile

  .centeredDiv {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column-reverse;
    align-items: center;
  }

  .helloDiv {
    font-size: 14px;
  }

  .headline {
    margin-top: 20px;
    font-size: 32px;
  }

  .text {
    margin-top: 16px;
    font-size: 14px;
    width: calc(100vw - 64px);
  }

  .ctaContainer {
    width: calc(100vw - 64px);
    margin-top: 32px;
  }
}
