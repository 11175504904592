.dataTableContainer {
  margin-top: 60px;
}

.patientInfo,
.patientTable {
  @media only screen and (max-width: 992px) {
    .dataRow {
      flex-direction: row;
      padding: 10px !important;
    }
  }

  .mobileTable {
    @media only screen and (min-width: 993px) {
      margin-right: 20px;
    }

    .topSortMobile {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .sort {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
          margin: 0;
          opacity: 1;
          color: rgba(156, 156, 156, 1);
          font-family: "HelveticaNeue";
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
        }
      }
    }

    .mobileWrapper {
      opacity: 1;
      background-color: rgba(244, 244, 243, 1);
      padding: 12px;
      margin-bottom: 12px;

      .patientName {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(156, 156, 156, 0.2);
        padding-bottom: 12px;

        h3 {
          margin: 0;
          opacity: 1;
          color: rgba(31, 31, 31, 1);
          font-family: "HelveticaNeue-Bold";
          font-size: 16px;
          font-weight: 700;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
        }
      }

      .choosePractitioner {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        border-bottom: 1px solid rgba(156, 156, 156, 0.2);
        padding: 12px 0;
        position: relative;

        .Dropdown {
          position: absolute;
          top: 0;
          right: 0;

          .dropdownLabel {
            margin-bottom: 10px;
          }
        }

        h4 {
          margin: 0;
          opacity: 1;
          color: rgba(156, 156, 156, 1);
          font-family: "HelveticaNeue";
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
        }

        .dropdownArrow {
          position: relative !important;
          top: unset !important;
        }

        .dropdownOpen {
          display: flex !important;
          align-items: baseline !important;
          flex-direction: column !important;

          img {
            display: none !important;
          }
        }

        .dropdownClosed {
          display: flex !important;
          align-items: baseline !important;
          justify-content: end !important;
          gap: 10px !important;
          text-decoration: underline !important;

          .selectedOption,
          .selectedOptionPlaceholder.dropdownPractitioner {
            margin-top: 0 !important;
          }
        }
      }

      .mobileDate {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(156, 156, 156, 0.2);
        padding: 12px 0;

        h4 {
          margin: 0;
          opacity: 1;
          color: rgba(156, 156, 156, 1);
          font-family: "HelveticaNeue";
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
        }

        p {
          margin: 0;
          opacity: 1;
          color: rgba(31, 31, 31, 1);
          font-family: "HelveticaNeue-Medium";
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: 0px;
          text-align: right;
        }
      }

      .seeMoreMob {
        padding-top: 12px;
      }
    }
  }
}

.ConfirmPopUpContent {
  display: flex;
  flex-direction: column;
}

.Patients {
  @media only screen and (max-width: 992px) {
    margin-top: 50px;
  }
}

.patientsAction {
  display: flex;
  justify-content: end;
  padding-right: 29px;
  height: 48px;

  @media only screen and (min-width: 993px) {
    margin-right: 20px;
  }
}
