.concernsContainer {
  margin-top: 60px;
}

.nextContainer {
  width: 240px;
  height: 45px;
  margin-top: 50px;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 40px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 91px;
  flex-direction: column;
}

@media (max-width:641px) { // mobile
  .nextContainer {
    width: calc(100vw - 64px);
    margin-top: 32px;
    font-size: 14px;
    margin-bottom: 40px;
  }
}