
.videoContainer {
  position: relative;
  width: 600px;
  height: 405px;
  border-radius: 15px;
}

.videoContainerLanding {
  position: relative;
  width: 1081px;
  height: 608px;
}

.playContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
}



@media (max-width:641px) { // mobile
  .videoContainer {
    border-radius: 7.428553px;
    width: 297px;
    height: 200px;
    margin-bottom: 30px;
  }
  // .videoContainer {
  //   border-radius: 7.428553px;
  //   width: 100%;
  //   height: 200px;
  //   margin-bottom: 40px;
  //   margin-top: 30px;
  //   display: flex;
  //   justify-content: center;
  // }

  .videoContainerLanding {
    width: 297px;
    height: 200px;
  }
}

// @media (max-width:380px){
//   .videoContainer {
//     margin-top: 240px;
//   }
// }