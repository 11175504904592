.mainBox {
  display: flex;
}

.innerBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  //align-items: center;
  overflow: hidden;
}

.routerBox {
  overflow: hidden;
}

.containerForSideNavigation {
  width: 360px;

  @media only screen and (max-width: 992px) {
    display: none;
  }
}

.containerForMobileSideNavigation {
  display: none;

  @media only screen and (max-width: 992px) {
    display: block;
    // position: absolute;
    // right: 20px;
    // top: 64px;
    z-index: 2;
  }
}

.mobileMenu {
  background-color: #fff;
  position: fixed;
  width: 300px;
  height: 100%;
  right: 0;
  float: right;
  z-index: 4;
  height: 100vh;
  box-shadow: 2px 0px 5px black;
  display: flex;
  align-items: center;
  overflow: scroll;

  span {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .SideNavigation {
    width: 100%;
    position: relative;
    padding: 0px 40px;

    .logoContainer {
      width: 100%;

      .Logo {
        text-align: center;
      }
    }

    .NavigationElements {
      text-align: center;
      align-items: center;
      justify-content: flex-start;

      .SideNavigationElement {
        width: 230px;
      }
    }
  }
}

.blur {
  position: fixed;
  height: 100%;
  backdrop-filter: blur(10px);
  width: 100%;
  z-index: 3;
  left: 0;
  overflow: hidden;
}

@media only screen and (max-width: 992px) {
  .main {
    padding: 0 20px 50px 20px;
  }
  .searchMenuWrapper {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: end;

    .Search {
      .searchContainer {
        & > div {
          width: 100%;
          padding-left: 50px;
        }
      }
    }
  }
}

.WrapInput {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  overflow: hidden;
}

.InputSize {
  width: 48%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

.screenButtonWrapper {
  justify-content: center;
}
