.mainProfile {
  margin-top: 80px;
  width: 1000px;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }

  .practiceLogo{
    width: 20%;

    @media only screen and (max-width: 992px){
      width: 50%;
    }
  }
}

.integrations{
  margin-bottom: 200px;
}

.integrationDropdown{
  width: 640px;

   @media only screen and (max-width: 992px) {
    width: 100%;
  }

  .dropdownLable{
    opacity: 1;
color: rgba(73, 73, 73, 1);
font-size: 14px;
font-weight: 400;
letter-spacing: 0px;
text-align: left;
margin-left: 30px;
margin-bottom: 5px;
  }
}

.integrationDropdownWrapper{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dropdownGridWrap{
  display: flex;
  flex-direction: row;
  gap: 24px;

  div{
     width: 100%;
  }

  .Dropdown{
    width: 100%;


  }



  @media only screen and (max-width: 992px){
    flex-direction: column;
  }
}

.sectionChange {
  margin-top: 50px;
}

.sectionChangePlans {
  margin-top: 100px;
}

.textInputContainer {
  margin-top: 40px;
  width: 640px;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}

.buttonContainer {
  width: 192px;
  height: 32px;
}

.divider {
  height: 20px;
  width: 100%;
}

.uploadContainer {
  display: flex;
  margin-top: 50px;
}

.uploadText {
  font-size: 16px;
  color: #000000;
  width: 287px;
  margin-left: 43px;
  margin-top: 41px;
}

.uploadButtonContainer {
  width: 192px;
  height: 32px;
  margin-top: 26px;
}

.colorSchemeContainer {
  margin-top: 56px;
}

.businessPlanContainer {
  margin-top: 56px;
  width: 576px;
  min-height: 319px;
}

.forMyBusinessPlan {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1200px) and (min-width: 993px) {
    flex-direction: column;
  }
}

.additionalPlanText {
  margin-top: 71px;
  width: 332px;
  display: flex;
}

.additionalPlanTextOnly {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 21px;
  margin-left: 17px;
}

.additionalPlanIcon {
  width: 23.63px;
  height: 23.63px;
}

.additionalPlanButtonContainer {
  width: 332px;
  height: 40px;
}

.additionalPlanButtonContainerFirst {
  margin-top: 40px;
  margin-bottom: 17px;
}

.PlansContainer {
  margin-top: 42px;
  display: flex;
}
