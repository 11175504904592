.TextInputLabel {
  color: black;
  font-size: 14px;
  margin-left: 33px;
  opacity: 0.6;
}

.specificSurveyLink {
  opacity: 1;
  color: rgba(73,73,73,1) !important;
  font-size: 14px !important;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}
