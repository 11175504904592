.companyLogo {
  // width: 192px;
  // max-height: 80px;
  width: 100%;
  height: 100px;
  object-fit: contain;
}

.Logo {
  text-align: center;
}

.invisibleLogo {
  visibility: hidden;
}
