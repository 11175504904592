.SingleRate {
  border-radius: 7px;
  background-color: #F1EDE5;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1F1F1F;
  font-size: 18px;
  font-weight: 800;
  cursor: pointer;
  margin-bottom: 11px;
}