.HealthConcernRate {
  width: 720px;
}

.healthRates {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.healthRateNames {
  display: flex;
  justify-content: space-between;
}

.mobileHealthRateNames {
  display: none;
}

.severenessText {
  font-size: 14px;
  color: #494949;
}

.specificRateText {
  width: 60px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

@media (max-width:641px) { // mobile
  .HealthConcernRate {
    width: calc(100vw - 64px);
    margin-left: 0;
  }

  .mobileHealthRateNames {
    display: block;
    margin-bottom: 10px;
  }

  .healthRateNames {
    display: none;
  }
}