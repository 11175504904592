.UploadFile {
  width: 193px;
  height: 193px;
  border-radius: 15.08px;
  border-style: dashed;
  border-width: 3px;
  cursor: pointer;
}

.uploadImage {
  width: 32px;
  height: 35px;
  margin-left: 81px;
  margin-top: 45px;
}

.UploadFileText {
  text-align: center;
  color: #494949;
  font-size: 14px;
  margin-left: 22px;
  margin-right: 22px;
  margin-top: 27px;
}