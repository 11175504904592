.SideNavigation {
  width: 191px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 64px;
  position: fixed;
}

.logoContainer {
  text-align: center;
  width: 192px;

  .companyLogo {
    width: 100px;
    height: 100px;
    margin: auto;
    object-fit: contain;
  }

  .defaultLogo {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}

.NavigationElements {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 234px);
  justify-content: space-between;
}

.bottomElements {
}
