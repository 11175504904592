.Login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  @media only screen and (max-width: 600px) {
    height: 100%;
    padding: 30px 0;

    .Logo {
      @media only screen and (max-width: 600px) {
        margin-bottom: 0 !important;
      }
      .companyLogo {
        @media only screen and (max-width: 600px) {
          width: 50%;
        }
      }
    }
  }

  flex-direction: column;
}

.boxes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  @media only screen and (max-width: 600px) {
    span {
      font-size: 12px;
    }
  }
}

.loginLogo {
  // top: 64px;
  // position: absolute;
  // z-index: 1;
  // height: 48px;
  // width: 192px;
  @media only screen and (max-width: 992px) {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    display: block;
  }
  .Logo {
    margin-bottom: 50px;
  }
}

.headline-login {
  margin-bottom: 36px;
}

.authContainer {
  width: 832px;
  height: 558px;
  border-radius: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(238, 251, 255);
  z-index: 2;
}

.innerAuthContainer {
  width: 544px;
  height: 407px;
}

.divider {
  height: 22px;
  width: 100%;
}

.linkTextContainer {
  margin-right: 20px;

  a {
    cursor: pointer;
    color: #9c9c9c;
    font-size: 14px;
    line-height: 17px;
    text-decoration: underline;

    @media only screen and (max-width: 600px) {
      font-size: 12px;
    }

    &:hover {
      color: #9c9c9c;
    }
  }
}

.buttonContainer {
  width: 320px;
  height: 48px;
  margin-top: 50px;
}

.loginButton {
  width: 320px;
  height: 48px;
  margin-top: 40px;
}

.signUpTextContainer {
  margin-top: 29px;
  a {
    cursor: pointer;
    color: #9c9c9c;
    font-size: 14px;
    line-height: 17px;
    text-decoration: underline;

    &:hover {
      color: #9c9c9c;
    }
  }
}

@media screen and (max-width: 992px) {
  .authContainer {
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  .authContainer {
    width: 90% !important;

    .innerAuthContainer {
      height: auto;
      padding: 40px 12px;
    }
  }
}
