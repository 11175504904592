.testPrivacyWrap {
  margin: 50px 0;
  padding: 0 80px;

  @media only screen and (max-width: 992px) {
    padding: 0 30px;
  }

  h1 {
    font-size: 28px;
    text-align: center;
    margin: auto;
  }

  span {
    font-size: 14px;
    text-align: center;
    margin: auto;
    width: 100%;
    display: inherit;
  }

  .testPrivacyContent {
    margin-top: 60px;

    @media only screen and (max-width: 992px) {
      margin-top: 30px;
    }

    h2 {
      font-size: 24px;
      margin: 40px 0 10px 0;
    }

    p {
      font-size: 16px;
      margin: 0;
    }

    a {
      color: rgb(94, 147, 186);
    }
  }
}
