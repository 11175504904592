.SurveyCheckboxOption {
  width: calc(100% - 20px);
  height: 100%;
  border-radius: 25px;
  background-color: #f1ede5;
  display: flex;
  align-items: center;
  padding-right: 20px;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}

.surveyCheckboxText {
  opacity: 1;
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  text-align: left;

  @media only screen and (max-width: 992px) {
    font-size: 13px;
    word-break: break-all;
  }
}

.surveyCheckboxInput {
  width: 100% !important;
}

.surveyCheckboxInput input {
  opacity: 1;
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  border-bottom: none !important;
  font-family: "HelveticaNeueMedium", serif !important;

  @media only screen and (max-width: 992px) {
    font-size: 11px;
  }
}

.surveyCheckboxInput:after {
  border-bottom: none !important;
}

.surveyCheckboxInput:before {
  border-bottom: none !important;
}
