.updateWrapper {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;

  @media only screen and (max-width: 600px) {
    bottom: 10px;
    right: 10px;
    left: 10px;
    bottom: 10px;
  }

  .updateContent {
    position: relative;
    width: 406px;
    padding: 40px 30px;
    border-radius: 27px;
    border: 1px solid rgba(97, 132, 157, 1);
    box-shadow: 0px 0px 19px rgba(156, 156, 156, 0.203262);
    opacity: 1;
    background-color: rgba(236, 248, 254, 1);

    @media only screen and (max-width: 600px) {
      width: 100%;
    }

    .closeUpdateContent {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }

    h1 {
      opacity: 1;
      color: rgba(31, 31, 31, 1);
      font-family: "HelveticaNeue";
      font-size: 32px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0px;
      text-align: left;
      margin: 0;

      @media only screen and (max-width: 600px) {
        font-size: 24px;
      }
    }

    h2 {
      opacity: 1;
      color: rgba(31, 31, 31, 1);
      font-family: "HelveticaNeue-Bold";
      font-size: 16px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 10px;
    }

    h5 {
      opacity: 1;
      color: rgba(156, 156, 156, 1);
      font-family: "HelveticaNeue";
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 20px;
    }

    .updateListContent {
      max-height: 300px;
      overflow-y: scroll;
      overflow-x: hidden;

      @media only screen and (max-width: 600px) {
        // max-height: 60vh;
        // overflow: scroll;
      }

      .updateList {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        margin-bottom: 20px;
        gap: 12px;
        opacity: 1;
        color: rgba(156, 156, 156, 1);
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 20px;
        background-color: transparent;
        // max-height: 100px;
        // overflow: auto;

        &:last-child {
          margin-bottom: 0;
        }

        .content {
          background-color: transparent;
          ul {
            margin: 0;
            padding-left: 1em;
            margin: 0;
            opacity: 1;
            color: rgba(156, 156, 156, 1);
            font-family: "HelveticaNeue";
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
            text-align: left;
            line-height: 20px;
            margin: 0 0 20px;
          }
        }

        .text {
          width: 100%;
        }

        h2 {
          opacity: 1;
          color: rgba(73, 73, 73, 1);
          font-family: "HelveticaNeue";
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
          margin: 0;
          margin-bottom: 4px;
          background-color: transparent;
        }

        p,
        .text {
          margin: 0 0 20px;
          opacity: 1;
          color: rgba(156, 156, 156, 1);
          font-family: "HelveticaNeue";
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
          line-height: 20px;
          background-color: transparent;
        }

        a {
          color: #61849d;
          margin: 0 0 20px;
        }
      }
    }
  }
}
