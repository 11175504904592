.settingSection {
  margin-top: 24px;

  &:nth-child(1) {
    margin-top: 0;
  }
}

.SurveySettings {
  .dropdownContainer {
    margin-top: 20px;
  }

  .headline-settings {
    margin-top: 70px;
  }

  .headline-settingsGeneration {
    margin-top: 50px;
  }
}

.dropdownTitle {
  margin-bottom: 8px;
  margin-left: 20px;
  opacity: 1;
  color: rgba(31, 31, 31, 1);
  font-family: "HelveticaNeue-Bold";
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.DropdownStyle {
  width: 372px;
  margin: auto;
  margin-bottom: 40px;
}

.dropdownList {
  list-style-type: none;
  padding: 0;
  margin-left: 20px;
  border-left: 1px solid #61849d;
  padding-left: 20px;
  margin-bottom: 40px;

  li {
    margin-bottom: 16px;

    a {
      opacity: 1;
      color: rgba(156, 156, 156, 1) !important;
      font-family: "HelveticaNeue";
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0px;
      text-align: left;
      cursor: pointer;

      &::after {
        // content: url("http://localhost:3001//assets/arrow-small-right.svg");
        content: url("https://stage.chiropracticoutcomes.com/assets/arrow-small-right.svg");
        margin-left: 5px;
      }

      &:hover {
        opacity: 1;
        color: rgba(97, 132, 157, 1) !important;
        font-family: "HelveticaNeue-Bold";
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;

        &::after {
          // content: url("http://localhost:3001//assets/arrow-small-right-hover.svg");
          content: url("https://stage.chiropracticoutcomes.com/assets/arrow-small-right-hover.svg");
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.dropdownBottomTitles {
  margin-bottom: 40px;
  margin-left: 20px;
  padding: 0;
  list-style-type: none;

  li {
    opacity: 1;
    color: rgba(31, 31, 31, 1);
    font-family: "HelveticaNeue-Bold";
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 8px;
    width: fit-content;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .healthMetricsQuestions {
    p {
      margin: 0;
      opacity: 1;
      color: rgba(122, 126, 130, 1);
      font-family: "HelveticaNeue";
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}

.dropdownTitleCustomizable {
  opacity: 1;
  color: rgba(31, 31, 31, 1);
  font-family: "HelveticaNeue-Bold";
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 8px;
  margin-left: 20px;
  cursor: pointer;
}

.dropdownParagraphCustomizable {
  opacity: 1;
  color: rgba(122, 126, 130, 1);
  font-family: "HelveticaNeue-Medium";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 20px;
}

.dropdownContainer {
  margin-top: 40px;
}

.titleContainer {
  width: 708px;
  //margin-top: 35px;

  @media only screen and (max-width: 992px) {
    width: 70%;
  }

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}

.ScreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(97, 132, 157, 0.6);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}

.ScreenContent {
  border-radius: 27px;
  opacity: 1;
  background-color: rgba(238, 251, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 832px;
  height: fit-content;
  padding: 43px 74px;

  @media only screen and (max-width: 992px) {
    padding: 20px 10px;
    height: 100%;
    height: min-content;
    width: 90%;
  }

  .videoLinkContainer {
    margin-top: 0;
    width: 100%;
  }

  .TextInputContainer textarea {
    width: 100%;
  }

  .textContainer {
    width: 100%;
    height: auto;
  }

  h2 {
    opacity: 1;
    color: rgba(31, 31, 31, 1);
    font-family: "HelveticaNeue-Bold";
    font-size: 32px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
  }

  p {
    opacity: 1;
    color: rgba(31, 31, 31, 1);
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
  }
}

.screenButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 23px;
  margin-top: 40px;

  @media only screen and (max-width: 992px) {
    margin-top: 20px;
  }

  .saveForm {
    border: none;
    border-radius: 23px;
    box-shadow: 0px 2px 4px rgba(217, 217, 217, 1);
    opacity: 1;
    background-color: rgba(97, 132, 157, 1);
    width: 128px;
    height: 32px;
    opacity: 1;
    color: rgba(255, 255, 255, 1);
    font-family: "HelveticaNeue-Bold";
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
  }

  .closeForm {
    border: none;
    background: transparent;
    opacity: 1;
    color: rgba(31, 31, 31, 1);
    font-family: "HelveticaNeue-Medium";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    text-decoration: underline;
  }
}

.textContainer {
  width: 640px;
  height: 178px;

  @media only screen and (max-width: 992px) {
    width: 70%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

.videoLinkContainer {
  width: 708px;

  @media only screen and (max-width: 992px) {
    width: 70%;
    margin-top: 70px;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-top: 70px;
  }
}

.headline-settings {
  margin-top: 35px;
}

.saveContainer {
  width: 132px;
  height: 32px;

  margin-top: 30px;
}

.linkContainer {
  margin-top: 30px;
}

.autoClinicEmail {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 640px;
  margin-top: 16px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  .TextInputLabel {
    margin: 0;
  }
}

.linkButtonsContainer {
  display: flex;
}

.linkButtonContainer {
  width: 112px;
  height: 32px;
}

.linkButtonContainer:nth-child(2) {
  margin-left: 20px;
}

.addNewContainer {
  width: 135px;
  height: 32px;
}

@media only screen and (max-width: 600px) {
  .DropdownStyle {
    width: 100%;
  }
}


.loading-cursor {
  cursor: progress !important;
}