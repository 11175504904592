.BusinessPlan {
  width: 100%;
  // min-height: 100%;
  height: 100%;
  border-width: 1px;
  border-style: solid;
  padding: 30px 35px;
  border-radius: 24px;
  background-color: #f4f4f3;
  position: relative;
}

.BasicPlan {
  border-color: #61849d;

  .BusinessPlanHeader {
    border-bottom-color: #61849d;
  }

  .businessPlanItemTickBox {
    background-color: #61849d;
  }
}

.BusinessPlanBody {
  margin-bottom: 70px;
}

.AdvancedPlan {
  border-color: #86b49c;

  .BusinessPlanHeader {
    border-bottom-color: #86b49c;
  }

  .businessPlanItemTickBox {
    background-color: #86b49c;
  }
}

.EnterprisePlan {
  border-color: #ebc263;

  @media only screen and (max-width: 992px) {
    margin-top: 25px;
  }

  .BusinessPlanHeader {
    border-bottom-color: #ebc263;
  }

  .businessPlanItemTickBox {
    background-color: #ebc263;
  }
}

.BusinessPlanHeader {
  display: flex;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 28px;
  margin-bottom: 18px;
}

.pricingInfo {
  text-align: right;
}

.BusinessPlanHeaderMini {
  font-size: 14px;
  height: 20px;
  text-align: right;
}

.headerPlan {
  text-align: left;
}

.perMonth {
  text-align: right;
}

.BusinessPlanHeaderMaxi {
  font-size: 24px;
  font-weight: 500;
}

.businessPlanItem {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
}

.businessPlanItemTickBox {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.businessPlanItemText {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 15px;
  // margin-top: 5px;
}

.upgradeButtonContainer {
  width: calc(100% - 70px);
  height: 40px;
  position: absolute;
  bottom: 32px;
  left: 35px;
}

.business-plan-selected {
  border: 4px solid #61849d;
}
