.TabLine {
  height: 1px;
  width: 100%;
  display: flex;
  background-color: #ded6c7;
}

.activeTabLine {
  transition: margin-left 0.25s ease-in-out;

  @media only screen and (max-width: 992px) {
    width: 50%;
  }
}
