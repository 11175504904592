.Headline-big {
  font-size: 32px;
  text-align: center;
  line-height: 38px;
}

.Headline-medium {
  font-size: 24px;
  line-height: 28px;
}

.Headline-small {
  font-size: 18px;
  line-height: 22px;
}

.Headline-big-left {
  font-size: 32px;
  line-height: 38px;
}


.Headline-personalization {
  font-size: 24px;
  line-height: 32px;
}