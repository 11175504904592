.PlayIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.PlayIcon {
  border-radius: 50%;
  width: 68px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
}