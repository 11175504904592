.productTourContent {
  display: flex;
  flex-direction: row;
  margin-bottom: 27px;
  height: 600px;

  @media only screen and (min-width: 1700px) {
    height: 800px;
  }

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    margin-bottom: 0;
  }

  .leftSide {
    width: 50%;
    opacity: 1;
    background-image: linear-gradient(
      130deg,
      rgba(231, 241, 248, 1) 0%,
      rgba(207, 227, 241, 1) 25%,
      rgba(163, 203, 232, 1) 100%
    );
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 100px;

    @media only screen and (max-width: 1100px) {
      width: 100%;
      padding: 46px 19px 46px 29px;
    }

    .leftContent {
      h2 {
        opacity: 1;
        color: rgba(31, 31, 31, 1);
        font-family: "HelveticaNeue";
        font-size: 56px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 61px;
        margin-bottom: 13px;

        @media only screen and (max-width: 1100px) {
          opacity: 1;
          color: rgba(31, 31, 31, 1);
          font-family: "HelveticaNeue";
          font-size: 46px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
          line-height: 52px;
          margin-bottom: 23px;
        }
      }

      p {
        opacity: 1;
        color: rgba(73, 73, 73, 1);
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 23px;
        margin-bottom: 60px;

        @media only screen and (max-width: 1100px) {
          opacity: 1;
          color: rgba(73, 73, 73, 1);
          font-family: "HelveticaNeue";
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
          line-height: 23px;
          margin-bottom: 40px;
        }
      }

      .buttonsWrapp {
        display: flex;
        flex-direction: row;
        gap: 18px;

        @media only screen and (max-width: 1100px) {
          flex-direction: column;
        }

        .signup {
          border-radius: 10px;
          opacity: 1;
          background-color: rgba(94, 147, 186, 1);
          border: none;
          width: 196px;
          height: 48px;
          opacity: 1;
          color: rgba(255, 255, 255, 1);
          font-family: "HelveticaNeue-Medium";
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
          line-height: 19px;
          text-transform: uppercase;
          text-align: center;

          &:hover {
            color: #000;
          }
        }

        .watchDemo {
          border-radius: 10px;
          border: 1px solid rgba(73, 73, 73, 1);
          background-color: transparent;
          opacity: 1;
          width: 196px;
          height: 48px;
          opacity: 1;
          color: rgba(73, 73, 73, 1);
          font-family: "HelveticaNeue-Medium";
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
          line-height: 19px;
          text-transform: uppercase;
          text-align: center;
        }
      }
    }
  }

  .rightSide {
    width: 50%;
    overflow: hidden;
    position: relative;

    @media only screen and (max-width: 1100px) {
      width: 100%;
    }

    img {
      width: 100%;
      transform: scale(1.4);
      height: 600px;
      position: absolute;
      top: 0;
      object-fit: contain;
      @media only screen and (min-width: 1700px) {
        height: 800px;
      }
    }
  }
}

.demoOverlay {
  position: fixed;
  background-color: rgba(97, 132, 157, 0.6);
  backdrop-filter: blur(10px);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;

  .demoContent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: 1;

    .videoContainer {
      width: 100%;
      @media only screen and (min-width: 992px) {
        height: 500px;
      }
    }

    .videoClip {
      width: 900px !important;
      height: 500px !important;
      @media only screen and (max-width: 600px) {
        height: 100% !important;
        width: 300px !important;
      }
    }

    & > div {
      position: relative;

      // .demoVideo {
      //   width: 100%;
      // }

      .demoClose {
        position: absolute;
        right: 0px;
        top: -40px;
        cursor: pointer;
      }
    }
  }
}

.contentFooterWrap {
  @media only screen and (min-width: 1600px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90vh;
    width: 100%;
  }
}
