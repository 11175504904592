.ConfirmPopUpBackdrop {
  opacity: 0.6;
  width: 100%;
  height: 100%;
}

.ConfirmPopUp {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 3;
  display: none;
}

.blurredMainBox {
  filter: blur(10px);

  @media screen and (max-width: 1200px) {
    height: 100vh;
  }
}

.noScroll {
  overflow: hidden;
}

.ConfirmPopUpBox {
  border-radius: 27px;
  width: 832px;
  height: 478px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #eefbff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 161px;
  padding-right: 161px;
}

.ConfirmPopUpTitle {
  color: black;
  font-size: 32px;
  font-weight: 500;
  // margin-bottom: 42px;
  text-align: center;
}

.ConfirmPopUpDescription {
  color: black;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 42px;
  text-align: center;
}

.ConfirmPopUptopSecondTitle {
  opacity: 1;
  color: rgba(73, 73, 73, 1);
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 20px;
}

.ConfirmPopUpBottomDescription {
  opacity: 1;
  color: rgba(73, 73, 73, 1);
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1px;
  text-align: center;
  line-height: 24px;
}

.ConfirmPopUpBasicDescription {
  opacity: 1;
  color: rgba(31, 31, 31, 1);
  font-family: "HelveticaNeue-Medium";
  font-size: 16px;
  font-weight: 500;
  font-style: Medium;
  letter-spacing: 1px;
  text-align: center;
  line-height: 24px;
  width: 311px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 42px;
}

.ConfirmPopUpSubtitle {
  color: rgba(122, 126, 130, 1);
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: fit-content;
  position: absolute;
  top: 60px;
}

.ConfirmPopUpButtonContainer {
  width: 204px;
  height: 32px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.denyPopupButton {
  text-align: center;
  font-size: 14px;
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 641px) {
  // mobile

  .ConfirmPopUpBox {
    width: calc(100vw - 161px - 161px);
    max-height: 100vh;
  }

  .ConfirmPopUpButtonContainer {
    width: calc(100vw - 64px);
  }
}

@media only screen and (max-width: 992px) {
  .ConfirmPopUpBox {
    width: 80%;
  }

  .ConfirmPopUpTitle {
    padding: 0 20px;
  }

  .ConfirmPopUpButtonContainer {
    .Button {
      margin: auto;
      width: 200px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .ConfirmPopUpContent {
    // width: 100%;
  }

  .ConfirmPopUptopSecondTitle {
    font-size: 14px;
    margin-top: 10px;
  }

  .ConfirmPopUpTitle {
    padding: 0 20px;
    font-size: 25px;
    line-height: 25px;
  }

  .ConfirmPopUpBottomDescription {
    font-size: 14px;
  }

  .ConfirmPopUpBasicDescription {
    font-size: 14px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .ConfirmPopUpButtonContainer {
    width: 100%;
  }
}
