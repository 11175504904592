.dataTable {
  width: 100%;
  min-height: 460px;
  overflow: scroll;

  @media only screen and (max-width: 992px) {
    min-height: auto;
    margin-bottom: 40px;
  }
}

.patientsTable {
  .dropdownOpen {
    width: 297px;
  }

  .selectPractitioner {
    width: 131px;
  }

  .surveyDatePatient {
    width: 145px;
  }
}

.dataRow {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f4f4f3;
  // padding-right: 120px;
  padding-right: 20px;

  @media only screen and (max-width: 1024px) {
    height: fit-content;
    padding: 20px 0;

    // .surveyCell {
    //   width: 100%;
    // }

    .lastCellSurveys {
      // width: 100%;
      // justify-content: start;
      // margin: 10px 0;

      .smallButtonsCell {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    padding-right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    padding: 20px 0;

    .surveyCell {
      width: 100%;
    }

    .lastCellSurveys {
      width: 100%;
      justify-content: start;
      margin: 10px 0;

      .smallButtonsCell {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}

.dataRowNew {
  height: 50px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  background: #f4f4f3;
  // padding-right: 120px;
  padding-right: 20px;

  td:nth-child(2) {
    width: 100%;
    text-align: center;
  }

  td:nth-child(3) {
    width: 100%;
    text-align: right;
  }

  @media only screen and (max-width: 1024px) {
    height: fit-content;
    padding: 20px 0;

    td:nth-child(2) {
      width: 100%;
      text-align: left;
      padding-left: 10px;
    }

    td:nth-child(3) {
      width: 100%;
      text-align: left;
      padding-left: 10px;
    }

    // .surveyCell {
    //   width: 100%;
    // }

    .lastCellSurveys {
      // width: 100%;
      // justify-content: start;
      // margin: 10px 0;

      .smallButtonsCell {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    padding-right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    padding: 20px 0;

    .surveyCell {
      width: 100%;
    }

    .lastCellSurveys {
      width: 100%;
      justify-content: start;
      margin: 10px 0;

      .smallButtonsCell {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}

.dataRow:nth-child(2n) {
  background: white;
}

.dataRowNew:nth-child(2n) {
  background: white;
}

.headRow {
  display: flex;
  justify-content: space-between;
  padding-right: 120px;
}

.headRowNew {
  display: flex;
  padding-right: 120px;
}

.dataHead {
  color: #9c9c9c;
  font-size: 16px;
  text-align: left;
  font-weight: 100;
  height: 50px;
  padding-left: 50px;
  cursor: pointer;

  @media only screen and (max-width: 992px) {
    padding-left: 0;

    &:nth-child(2) {
      display: none;
    }
  }
}

.dataCell {
  font-size: 16px;
  font-weight: 500;
  color: black;
  padding-left: 50px;

  @media only screen and (min-width: 1200px) {
    &:nth-child(2) {
      margin-left: -100px;
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding-left: 30px;
  }
}

.lastCellPatients {
  font-size: 14px;
  cursor: pointer;
  @media only screen and (max-width: 992px) {
    text-align: right;
    padding-right: 20px;
    align-items: center;
  }
}

.lastCellUsers {
  width: 128px;
  display: flex;
  justify-content: end;
  padding-left: 0 !important;
}

.lastCellUsersContent {
  font-size: 14px;
  cursor: pointer;
}

.rightArrowIcon {
  margin-left: 16px;
}

.tableFooter {
  margin-left: 50px;
  margin-right: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 992px) {
    margin: 0;
    flex-direction: column;
    align-items: baseline;
    gap: 20px;
  }
}

.resultsInfo {
  color: #9c9c9c;
  font-size: 14px;
  font-weight: 400;
}

.viewAllResults {
  color: black;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 20px;
}

.viewAllResults:hover {
  text-decoration: underline;
}

.tableFooterRight {
  display: flex;
  align-items: center;
}

.page {
  opacity: 1;
  color: #9c9c9c;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  margin-right: 24px;
  cursor: pointer;
}

.activePage {
  color: #1f1f1f;
}

.sortIcon {
  margin-right: 12px;
}

.iconLeftPaginate {
  margin-right: 35px;
  cursor: pointer;
}

.iconRightPaginate {
  margin-left: 11px;
  cursor: pointer;
}

.nameHead {
  width: 302px;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}

.nameCell {
  width: 302px;

  @media only screen and (max-width: 992px) {
    width: 100%;
    padding-left: 10px;
  }
}

.typeCell {
  border-radius: 23px;
  width: 103px;
  height: 32px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0 !important;
}

.adminTypeCell {
  background-color: #2c4354;
  color: white;
}

.userTypeCell {
  background-color: #9cc0da;
  color: #1f1f1f;
}

.roleHead {
  width: 103px;
  display: flex;
  justify-content: center;
  padding-left: 0 !important;
}

.buttonHead {
  width: 128px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #61849d;
  font-size: 14px;
  border-radius: 23px;
  box-shadow: 0px 2px 4px rgba(217, 217, 217, 1);
  cursor: pointer;
  padding-left: 0 !important;

  @media only screen and (max-width: 992px) {
    width: 200px;
  }

  @media only screen and (max-width: 600px) {
    width: 70%;
  }
}

.lastCellSurveys {
  width: 320px;
  display: flex;
  justify-content: end;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    justify-content: center;
  }
}

.surveyCell {
  width: 250px;
}

.smallButtonContainers {
  width: 128px;
  height: 32px;
  margin-right: 16px;
}

.smallButtonsCell {
  display: flex;
  justify-content: end;
  align-items: center;
}

.viewResultsCell {
  width: 120px;
  cursor: pointer;
  margin-right: 16px;
}

.Users {
  .Table {
    @media only screen and (max-width: 1024px) {
      .nameCell {
        width: 30%;
        padding-left: 0;
      }

      .dataRow {
        justify-content: space-around;
        padding: 0 10px;
      }

      .headRow {
        justify-content: space-around;
        padding-right: 0;
      }

      // .headRow {
      //   padding-right: 0;

      //   .nameHead {
      //     width: 30%;
      //   }
      // }
    }
  }
}

.noPractitioner {
  color: #9c9c9c;
}

.practitionerName {
  color: #1f1f1f;
}

.selectPractitioner {
  cursor: pointer;
  display: flex;
  gap: 12px;
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.selectPractitionerImage {
  transform: rotate(270deg);
}

.selectPractitioner:hover {
  text-decoration: underline;
}

.selectCell {
  padding-left: 10px;
}
