.PricePlan {
  margin-bottom: 70px;

  .mostPopular {
    opacity: 1;
    color: rgba(134, 180, 156, 1);
    font-family: "HelveticaNeue-Italic";
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 0px;
    text-align: left;
    margin-left: 30px;
  }

  .hide {
    color: transparent;
    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }

  .business-plan-selected {
    border: none;
  }

  .AdvancedPlan {
    border-color: #86b49c !important;
    border-width: 1px;
    border-style: solid;

    .Button {
      background-color: #86b49c !important;
      border-color: #86b49c !important;

      .buttonText {
        color: #fff !important;
      }
    }
  }
}
