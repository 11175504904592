.SideNavigationElement {
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  height: 48px;
  width: 192px;
  padding-left: 17px;
  align-items: center;
  margin-bottom: 5px;
}

.SideNavigationElementIcon {
  width: 32px;
  height: 32px;
}

.SideNavigationElementText {
  font-size: 16px;
  line-height: 18px;
  color: #494949;
  margin-left: 16px;
}
