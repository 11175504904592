.healthMetricsWrapp {
  margin: 20px 0 40px;

  .healthWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 708px;

    @media only screen and (max-width: 768px) {
      width: 90%;
    }
  }

  .healthSection {
    width: 708px;
    border-radius: 24px;
    opacity: 1;
    background-color: rgba(244, 244, 243, 1);
    font-family: "HelveticaNeue";
    font-size: 400;

    position: relative;
    border: 1px solid transparent;

    @media only screen and (max-width: 768px) {
      width: 90%;
    }

    .container {
      padding: 15px 20px;

      @media only screen and (max-width: 1200px) {
        padding: 15px;
      }

      h5 {
        opacity: 1;
        color: rgba(180, 180, 180, 1);
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: left;
        margin: 0;
      }

      @media only screen and (max-width: 768px) {
        width: 100%;
      }

      .title {
        border-bottom: 1px solid #dadada;
        p {
          border: none;
          background-color: transparent;
          width: 100%;
          outline: none;
          opacity: 1;
          color: rgba(73, 73, 73, 1);
          font-family: "HelveticaNeue";
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 0px;
          text-align: left;
          height: fit-content !important;
          display: flex;
          resize: none;
          min-width: fit-content !important;
          padding-left: 5px;
          padding-top: 5px;
          border-radius: 0;

          &.haveSpace {
            height: fit-content !important;
            padding-bottom: 15px;
            // margin-top: 20px !important;
          }

          &::placeholder {
            opacity: 1;
            color: rgba(73, 73, 73, 1) !important;
            font-family: "HelveticaNeue";
            font-size: 24px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
            text-align: left;
          }

          &:disabled {
            color: #aaaaaa;
          }
        }
      }

      .spacing {
        padding: 0;
        border-bottom: 1px solid #dadada;
        p {
          width: 100%;
          opacity: 1;
          color: rgba(73, 73, 73, 1);
          font-family: "HelveticaNeue-Bold";
          font-size: 16px;
          font-weight: 700;
          font-style: Bold;
          letter-spacing: 0px;
          text-align: left;
          padding-top: 5px;
          padding-bottom: 12px;
          border-radius: 0;
          width: 85%;

          &.haveSpace {
            height: fit-content !important;
            padding-bottom: 15px;
          }

          &::placeholder {
            opacity: 1;
            color: rgba(73, 73, 73, 1) !important;
            font-family: "HelveticaNeue";
            font-size: 24px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
            text-align: left;
          }

          &:disabled {
            color: #aaaaaa;
          }
        }
      }

      .scrollMore {
        font-style: italic;
        color: #61849d;
        font-size: 14px;
        position: absolute;
        margin-bottom: 0;
        left: 25px;
      }

      .questionField {
        padding-top: 10px;
        padding-bottom: 10px;
        p {
          opacity: 1;
          color: rgba(73, 73, 73, 1);
          font-family: "HelveticaNeue";
          font-size: 18px;
          font-weight: 400;
          font-style: Regular;
          letter-spacing: 0px;
          text-align: left;

          padding-top: 0;

          &.haveSpace {
            height: fit-content !important;
          }

          &::placeholder {
            opacity: 1;
            color: rgba(73, 73, 73, 1);
            font-family: "HelveticaNeue";
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
            text-align: left;

            &:disabled {
              color: #aaaaaa !important;
            }
          }

          &:disabled {
            color: #aaaaaa;
          }
        }

        .order2,
        .order3 {
          display: flex;
          flex-direction: column;
          gap: 15px;
          padding: 22px 0;

          .checkboxWrapper {
            display: flex;
            flex-direction: row;
            gap: 16px;
            color: #9c9c9c;
            font-size: 14px;

            input {
              width: 20px;
              height: 20px;
            }
          }
        }

        .order4 {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 30px;
          padding-top: 15px;

          @media only screen and (max-width: 600px) {
            gap: 15px;
          }

          .star {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            color: #aaaaaa;
          }
        }

        .order5 {
          display: flex;
          flex-direction: row;
          gap: 30px;
          color: #9c9c9c;
          padding: 10px 0 5px;

          @media only screen and (max-width: 600px) {
            flex-wrap: wrap;
            gap: 10px;
          }

          .numbers {
            width: 25px;
            height: 25px;
            border-radius: 2px;
            opacity: 0.159877;
            background-color: #dbdbdb;
            opacity: 1;
            color: #aaaaaa;
            font-family: "HelveticaNeue";
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
            text-align: left;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .pdfField {
        border-top: 1px solid #dadada;
      }

      .scaleWording {
        padding-top: 12px;
        border-top: 1px solid #dadada;
        .scaleWordingContent {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding-top: 10px;

          @media only screen and (max-width: 1200px) {
            display: none;
          }

          p {
            opacity: 1;
            color: rgba(73, 73, 73, 1);
            font-family: "HelveticaNeue";
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0px;
            text-align: left;
            line-height: 32px;
          }

          .scaleRates {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;

            .rateField {
              border-radius: 2px;
              opacity: 1;
              background-color: rgba(236, 236, 236, 1);
              width: 25px;
              height: 25px;
              opacity: 1;
              color: rgba(156, 156, 156, 1);
              font-family: "HelveticaNeue";
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .scaleWordingContentMobile {
          .upperContent {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            p {
              opacity: 1;
              color: rgba(73, 73, 73, 1);
              font-family: "HelveticaNeue";
              font-size: 16px;
              font-weight: 400;
              font-style: Regular;
              letter-spacing: 0px;
              text-align: left;
              line-height: 32px;
            }
          }

          .scaleRates {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;

            .rateField {
              border-radius: 2px;
              opacity: 1;
              background-color: rgba(236, 236, 236, 1);
              width: 25px;
              height: 25px;
              opacity: 1;
              color: rgba(156, 156, 156, 1);
              font-family: "HelveticaNeue";
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          @media only screen and (min-width: 1200px) {
            display: none;
          }
        }
      }

      .editButton {
        border-radius: 23px;
        box-shadow: 0px 2px 4px rgba(217, 217, 217, 1);
        opacity: 1;
        background-color: rgba(97, 132, 157, 1);
        border: none;
        opacity: 1;
        color: rgba(255, 255, 255, 1);
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 500;
        font-style: Medium;
        letter-spacing: 0px;
        text-align: left;
        width: 66px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 20px;
        right: 20px;

        @media only screen and (max-width: 1200px) {
          top: 10px;
          right: 10px;
        }
      }

      .revertButton {
        position: absolute;
        top: 20px;
        right: 100px;
        background-color: transparent;
        border-radius: 23px;
        border: 1px solid rgba(97, 132, 157, 1);
        box-shadow: 0px 2px 4px rgba(217, 217, 217, 1);
        opacity: 1;

        color: rgba(97, 132, 157, 1);
        font-family: "HelveticaNeue-Medium";
        font-size: 14px;
        font-weight: 500;
        font-style: Medium;
        letter-spacing: 0px;

        width: 83px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 1200px) {
          top: 10px;
          right: 85px;
        }
      }
    }
  }

  .accordion-content {
    padding: 0;
  }
}

.steperBackdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(97, 132, 157, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .popupStyle {
    border-radius: 27px;
    opacity: 1;
    background-color: rgba(238, 251, 255, 1);
    padding: 40px 90px;
    width: 832px;

    position: relative;

    &.createPartner {
      height: 600px;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 992px) {
        > div {
          width: 100%;
        }
      }

      .popupButtons {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 20px 0 0 0;

        .createButton {
          height: 32px;
          width: 192px;
        }
      }
    }

    @media only screen and (max-width: 1500px) {
      height: 700px;
      overflow: scroll;
    }

    .show {
      display: block;
    }

    .hide {
      display: none;
    }

    @media only screen and (max-width: 1200px) {
      height: 500px;
      overflow: scroll;
    }

    h2 {
      opacity: 1;
      color: rgba(31, 31, 31, 1);
      font-family: "HelveticaNeue-Bold";
      font-size: 32px;
      font-weight: 700;
      font-style: Bold;
      letter-spacing: 0px;
      text-align: center;

      @media only screen and (max-width: 1200px) {
        font-size: 24px;
      }
    }

    h4 {
      opacity: 1;
      color: rgba(31, 31, 31, 1);
      font-family: "HelveticaNeue-Bold";
      font-size: 16px;
      font-weight: 700;
      font-style: Bold;
      letter-spacing: 0px;
      text-align: center;
      margin-bottom: 40px;

      @media only screen and (max-width: 1200px) {
        margin-bottom: 20px;
        font-size: 14px;
      }
    }

    .stepInfo {
      opacity: 1;
      color: rgba(31, 31, 31, 1);
      font-family: "HelveticaNeue";
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: left;
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 40px;

      @media only screen and (max-width: 1200px) {
        margin-bottom: 20px;
        font-size: 14px;
      }
    }

    .inputWrap {
      display: flex;
      flex-direction: column;
      gap: 8px;
      position: relative;

      label {
        opacity: 1;
        color: rgba(73, 73, 73, 1);
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: left;
        padding-left: 30px;
      }

      input {
        border-radius: 24px;
        border: 1px solid transparent;
        opacity: 1;
        background-color: rgba(255, 255, 255, 1);
        opacity: 1;
        color: rgba(73, 73, 73, 1);
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: left;
        padding: 15px 0 15px 30px;
        outline: none;
        margin-bottom: 30px;

        @media only screen and (max-width: 1200px) {
          margin-bottom: 10px;
        }

        &:focus {
          border: 1px solid rgba(97, 132, 157, 1);
        }
      }

      textarea {
        margin-bottom: 30px;
        opacity: 1;
        background-color: rgba(255, 255, 255, 1);
        opacity: 1;
        color: rgba(73, 73, 73, 1);
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: left;

        &:focus {
          border: 1px solid rgba(97, 132, 157, 1);
        }

        @media only screen and (max-width: 1200px) {
          width: 100%;
          margin-bottom: 20px;
        }
      }

      .inputLengthCounter {
        position: absolute;
        right: 33px;
        top: 40%;
        opacity: 1;
        color: rgba(156, 156, 156, 1);
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: left;
      }
    }

    .scoreWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;

      @media only screen and (max-width: 1200px) {
        flex-direction: column;
        gap: 20px;
        margin-top: 15px;

        .inputWrap {
          &:nth-child(1) {
            input {
              margin: 0;
            }
          }
        }
      }

      .inputWrap {
        width: 100%;
      }
    }

    .popupButtons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: auto;
      width: fit-content;
      gap: 23px;
    }

    .submitFormBack {
      opacity: 1;
      color: rgba(73, 73, 73, 1);
      font-size: 14px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: left;
      text-decoration: underline;
      background-color: transparent;
      border: none;
      height: 32px;
      margin-top: 20px;
    }

    .submitForm {
      border-radius: 23px;
      box-shadow: 0px 2px 4px rgba(217, 217, 217, 1);
      opacity: 1;
      background-color: rgba(97, 132, 157, 1);
      border: none;
      margin: auto;
      text-align: center;
      width: 128px;
      height: 32px;
      opacity: 1;
      color: rgba(255, 255, 255, 1);
      font-family: "HelveticaNeue-Bold";
      font-size: 14px;
      font-weight: 700;
      font-style: Bold;
      letter-spacing: 0px;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }

    .closePopup {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }

    @media only screen and (max-width: 1200px) {
      width: 90%;
      padding: 20px 30px;
    }
  }
}

.revertPopup {
  .revertContent {
    .fields {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-bottom: 1px solid rgba(151, 151, 151, 0.2);
      padding: 20px 40px 20px;

      @media only screen and (max-width: 1200px) {
        padding: 20px;
      }

      h3 {
        opacity: 1;
        color: rgba(73, 73, 73, 1);
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 400;
        font-style: Regular;
        letter-spacing: 0px;
        text-align: left;
        margin: 0;
      }

      h5 {
        opacity: 1;
        color: rgba(73, 73, 73, 1);
        font-family: "HelveticaNeue-Bold";
        font-size: 14px;
        font-weight: 700;
        font-style: Bold;
        letter-spacing: 0px;
        text-align: left;
        margin: 0;
      }
    }
  }

  .confirmMessage {
    margin: 50px 0;

    @media only screen and (max-width: 1200px) {
      margin: 35px 0;
    }

    h2 {
      opacity: 1;
      color: rgba(73, 73, 73, 1);
      font-family: "HelveticaNeue-Bold";
      font-size: 18px;
      font-weight: 700;
      font-style: Bold;
      letter-spacing: 0px;
      text-align: left;
    }
  }

  .revertButtons {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .apply {
      border-radius: 23px;
      box-shadow: 0px 2px 4px rgba(217, 217, 217, 1);
      opacity: 1;
      background-color: rgba(97, 132, 157, 1);
      opacity: 1;
      color: rgba(255, 255, 255, 1);
      font-family: "HelveticaNeue-Medium";
      font-size: 14px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      border: none;
      width: 332px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;

      @media only screen and (max-width: 1200px) {
        width: 60%;
      }
    }

    .cancel {
      opacity: 1;
      color: rgba(31, 31, 31, 1);
      font-family: "HelveticaNeue-Medium";
      font-size: 14px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-decoration: underline;
      background-color: transparent;
      border: none;
    }
  }
}
