@font-face {
  font-family: "NewAthenaRegular";
  src: local("NewAthena"), url("./fonts/NewAthena.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNeueRegular";
  src: local("AnyConv.com__HelveticaNeue-01"),
    url("./fonts/AnyConv.com__HelveticaNeue-01.woff2") format("woff2");
}

@font-face {
  font-family: "HelveticaNeueBold";
  src: local("AnyConv.com__HelveticaNeue-Bold-02"),
    url("./fonts/AnyConv.com__HelveticaNeue-Bold-02.woff2") format("woff2");
}

@font-face {
  font-family: "HelveticaNeueMedium";
  src: local("AnyConv.com__HelveticaNeue-Medium-11"),
    url("./fonts/AnyConv.com__HelveticaNeue-Medium-11.woff2") format("woff2");
}

@font-face {
  font-family: "HelveticaNeueThin";
  src: local("AnyConv.com__HelveticaNeue-Thin-13"),
    url("./fonts/AnyConv.com__HelveticaNeue-Thin-13.woff2") format("woff2");
}

@font-face {
  font-family: "HelveticaNeueLight";
  src: local("AnyConv.com__HelveticaNeue-Light-08"),
    url("./fonts/AnyConv.com__HelveticaNeue-Light-08.woff") format("woff");
}

body {
  margin: 0;
  font-family: "HelveticaNeueMedium", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
