.autoSelectBox {
  display: flex;
  margin-left: -32px;

  @media only screen and (max-width: 992px) {
    flex-wrap: wrap;
    margin-bottom: 20px;

    .checkboxContainerBox {
      margin-bottom: 0;
    }
  }
}

.autoSelectTitle {
  font-size: 14px;
  color: #494949;
  margin-bottom: 15px;
}

.checkboxContainerBox {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.checkboxCustomLabel {
  font-size: 14px;
  color: #494949;
  cursor: pointer;
  margin-left: -10px;
}

.statisticsDropdownContainer {
  width: 372px;
  margin-top: 20px;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}

.Statistics {
  .desktopView {
    @media only screen and (max-width: 767px) {
      display: none;
    }

    @media only screen and (max-width: 1100px) and (min-width: 993px) {
      display: none;
    }
  }

  .mobileView {
    @media only screen and (min-width: 768px) {
      display: none;
    }

    @media only screen and (max-width: 1100px) and (min-width: 993px) {
      display: block;
    }
  }
}
