.survey-main {
  position: relative;
}

.logo,
.logoSet {
  position: absolute;
  top: 58px;
  left: 93px;
  // width: 174px;
  // max-height: 80px;
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.logoSet {
  position: absolute;
  top: 40px;
  left: 93px;
  // width: 174px;
  // max-height: 80px;
  width: 100px;
  height: 100px;
  object-fit: contain;

  @media only screen and (max-width: 1200px) {
    left: 0;
    right: 0;
    margin: auto;
  }

  @media only screen and (max-width: 600px) {
    top: 10px;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.unvisible-logo {
  visibility: hidden;
}

@media (max-width: 641px) {
  // mobile
  .logo {
    position: absolute;
    top: 16px;
    left: 0;
    right: 0;
    margin: 0 auto;
    // width: 112px;
    // max-height: 50px;
    // object-fit: cover;
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 992px) {
  .logo {
    left: 0;
    right: 0;
    margin: auto;
  }
}
