.resetPasswordWrapper {
  height: 100vh;

  @media only screen and (max-width: 1600px) {
    height: 100%;
    padding-bottom: 100px;
  }

  .companyLogo {
    width: 200px;
    height: 200px;
  }

  .otpWrapper {
    width: 832px;
    border-radius: 27px;
    opacity: 1;
    background-color: rgba(238, 251, 255, 1);
    margin: auto;

    @media only screen and (max-width: 1024px) {
      width: 85%;
    }

    .otpContent {
      padding: 72px 144px 70px;

      @media only screen and (max-width: 1024px) {
        padding: 70px 50px;
      }

      @media only screen and (max-width: 600px) {
        padding: 40px 20px;
      }

      h2 {
        opacity: 1;
        color: rgba(31, 31, 31, 1);
        font-family: "HelveticaNeue-Medium";
        font-size: 32px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        text-align: center;
      }

      .resetPasswordTitle {
        margin-bottom: 58px;
      }

      p {
        opacity: 1;
        color: rgba(73, 73, 73, 1);
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        margin: 17px 0 48px 0;
        text-align: center;

        @media only screen and (max-width: 1024px) {
          text-align: center;
        }
      }

      .changeComplete {
        text-align: center;

        .logInAccBtn {
          border-radius: 23px;
          box-shadow: 0px 2px 4px rgba(217, 217, 217, 1);
          opacity: 1;
          background-color: rgba(97, 132, 157, 1);
          opacity: 1;
          color: rgba(255, 255, 255, 1);
          font-family: "HelveticaNeue-Medium";
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: 0px;
          border: none;
          text-align: center;
          height: 48px;
          width: 70%;
          margin: auto;

          @media only screen and (max-width: 1024px) {
            width: 50%;
            margin: auto;
            margin-top: 40px;
          }

          @media only screen and (max-width: 600px) {
            width: 100%;
            margin-top: 20px;
          }
        }
      }

      .fieldsWrapper {
        .verifyButton {
          display: flex;
          flex-direction: column;
          text-align: center;
          gap: 16px;

          .verifySubmit {
            border-radius: 23px;
            box-shadow: 0px 2px 4px rgba(217, 217, 217, 1);
            opacity: 1;
            background-color: rgba(97, 132, 157, 1);
            opacity: 1;
            color: rgba(255, 255, 255, 1);
            font-family: "HelveticaNeue-Medium";
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: 0px;
            border: none;
            text-align: center;
            height: 48px;
            width: 70%;
            margin: auto;
            margin-top: 36px;

            @media only screen and (max-width: 1024px) {
              width: 50%;
              margin: auto;
              margin-top: 40px;
            }

            @media only screen and (max-width: 600px) {
              width: 100%;
              margin-top: 20px;
            }
          }

          .cancelForm {
            background: transparent;
            border: none;
            width: fit-content;
            margin: auto;
            opacity: 1;
            color: #9c9c9c;
            font-family: "HelveticaNeue-Medium";
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: 0px;
            text-align: left;
            text-decoration: underline;
          }
        }

        .passwordRequire {
          p {
            opacity: 1;
            color: rgba(73, 73, 73, 1);
            font-family: "HelveticaNeue";
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
            text-align: left;
            margin: 0;
            margin-left: 40px;
          }

          ul {
            margin-bottom: 20px;
            li {
              opacity: 1;
              color: rgba(73, 73, 73, 1);
              font-family: "HelveticaNeue";
              font-size: 14px;
              font-weight: 400;
              font-style: normal;
              letter-spacing: 0px;
              text-align: left;
              margin: 0;
              margin-left: 40px;
            }
          }

          @media only screen and (max-width: 600px) {
            display: none;
          }
        }

        .passwordRequireMob {
          display: none;

          p {
            opacity: 1;
            color: #7a7e82;
            font-family: "HelveticaNeue";
            font-size: 12px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
            text-align: center;
            margin: 0;
            margin-bottom: 10px;
          }

          @media only screen and (max-width: 600px) {
            display: block;
          }
        }
      }
    }
  }
}
