.Tab {
  width: 100%;
  text-align: center;
  padding-bottom: 12px;
  color: #9C9C9C;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
}

.activeTab{
  color: #1F1F1F;
}