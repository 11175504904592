.HealthConcernText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
}

.HealthConcernText:last-child {
  margin-bottom: 80px;
}

.healthTopHeadline {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  width: auto;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
}

.healthHeadline {
  margin-top: 30px;
  text-align: center;
  font-size: 40px;
  opacity: 1;
  color: #1F1F1F;
  font-weight: 400;
  width: 700px;
  font-family: NewAthenaRegular, serif;
  word-wrap: break-word;
}

.healthDescription {
  margin-top: 9px;
  font-size: 16px;
  font-weight: 400;
  width: 480px;
  text-align: center;
}

.healthTextContainer {
  width: 720px;
}

.healthConcernRatesContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.healthConcernSpecificRatesContainer {
  width: 395px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noTextSeparator {
  width: 100%;
  height: 14.5px;
}

.optionContainer {
  width: 600px;
  height: 45px;
  margin-bottom: 20px;
}

@media (max-width:641px) { // mobile
  .healthTextContainer {
    width: calc(100vw - 64px);
  }

  .healthDescription {
    width: calc(100vw - 64px);
    font-size: 14px;
  }

  .healthHeadline {
    width: calc(100vw - 64px);
    font-size: 32px;
    margin-bottom: 16px;
    line-height: 1.2;
    word-wrap: break-word;
  }

  .HealthConcernText {
    margin-bottom: 100px;
  }

  .HealthConcernText:last-child {
    margin-bottom: 80px;
  }

  .optionContainer {
    width: calc(100vw - 64px);
    min-height: 45px;
    margin-bottom: 16px;
  }

  .healthConcernSpecificRatesContainer {
    width: calc(100vw - 64px);

  }

}


