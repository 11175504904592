// .HelpCenter {
//   margin-top: -64px;
// }

.helpCenterText {
  opacity: 1;
  color: rgba(156, 156, 156, 1);
  font-family: "HelveticaNeue";
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  width: 55%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

.webVersion {
  position: absolute;
  right: 30px;
  bottom: 30px;
  border: none;
  padding: 10px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.298);
  font-size: 12px;
  line-height: 18px;
  color: #494949;
  border-radius: 6px;

  @media only screen and (max-width: 600px) {
    right: 10px;
    bottom: 10px;
  }
}

.helpCenterLink {
  opacity: 1;
  color: #7a7e82;
  font-family: "HelveticaNeue";
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  text-decoration: underline;

  &:hover {
    color: #61849d;
  }
}

.helpCenterContent {
  margin-top: 50px;
}

.videoPlayer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  margin-top: 100px;
  margin-bottom: 100px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  .videoContainer {
    width: 630px;
    @media only screen and (min-width: 600px) {
      height: 360px;
    }

    @media only screen and (max-width: 600px) {
      width: 100%;
      height: 205px;
      margin-bottom: 0;
    }

    .playContainer {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      cursor: pointer;
      width: 100%;
      height: 80%;
    }

    .PlayIconContainer {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .videoContent {
    position: relative;

    .videoClip {
      width: 100% !important;
      // height: 100% !important;
    }

    @media only screen and (max-width: 600px) {
      .videoClip {
        width: 100% !important;
        height: 205px !important;
      }
    }

    .react-player__preview {
      border-radius: 15px;
    }

    iframe {
      border-radius: 15px !important;
    }

    h4 {
      position: absolute;
      bottom: 20px;
      left: 30px;
      opacity: 1;
      color: rgba(255, 255, 255, 1);
      font-family: "HelveticaNeue-Medium";
      font-size: 32px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: 0px;
      text-align: left;
      z-index: 1;

      @media only screen and (max-width: 600px) {
        bottom: 5px;
      }

      @media only screen and (max-width: 375px) {
        font-size: 20px;
        bottom: 15px;
      }
    }

    .shadowImage {
      width: 100%;
      // height: 150px;
      position: absolute;
      bottom: 0;

      @media only screen and (max-width: 1100px) {
        height: 170px;
      }

      @media only screen and (max-width: 600px) {
        bottom: 0px;
        height: 80px;
      }

      @media only screen and (max-width: 375px) {
        bottom: 9px;
        border-radius: 0;
        height: 50px;
      }
    }

    .is-active {
      position: absolute;
      top: 20px;
      opacity: 1;
      color: rgba(255, 255, 255, 1);
      font-family: "HelveticaNeue-Medium";
      font-size: 32px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: 0px;
      text-align: left;
      z-index: 1;
      height: fit-content;

      @media only screen and (max-width: 375px) {
        font-size: 20px;
        top: 20px;
      }
    }

    .activeShadow {
      top: 0;
      position: absolute;
      width: 100%;
      height: 100px;
      transform: rotate(180deg);
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      @media only screen and (max-width: 500px) {
        height: 50px;
      }
      @media only screen and (max-width: 375px) {
        top: 9px;
        border-radius: 0;
        height: 50px;
      }
    }

    .PlayIcon {
      z-index: 1;
    }

    .react-player__shadow {
      background-color: rgb(97, 132, 157) !important;
      z-index: 1;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: none;
    }
  }
}

.videoLinks::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.videoLinks::-webkit-scrollbar-thumb {
  background: rgba(90, 90, 70);
  border-radius: 20px;
}

.videoLinks::-webkit-scrollbar-track {
  background: transparent;
}

.videoLinks {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 140px;
  overflow-y: scroll;
  width: min-content;

  div {
    background: rgba(97, 132, 157, 0.2);
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    opacity: 1;
    color: rgba(73, 73, 73, 1) !important;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    font-style: italic;

    p {
      margin: 0;
      word-break: break-all;
      white-space: break-spaces;
      width: 800px;

      @media only screen and (max-width: 500px) {
        width: 200px;
      }
    }

    &:hover {
      text-decoration: underline;
      color: rgba(73, 73, 73, 1);
    }
  }
}

.ControlBar_module_controlBarWrapper__39bb3a81 {
  height: 100% !important;
}
