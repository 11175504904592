.pricingWrapper {
  padding-bottom: 60px;
  // .companyLogo {
  //   width: 200px;
  //   height: 200px;
  // }

  .pricingContent {
    border-radius: 27px;
    opacity: 1;
    background-color: rgba(238, 251, 255, 1);
    width: 90%;
    margin: auto;
    margin-top: 50px;

    .content {
      padding: 78px 58px 41px;

      @media only screen and (max-width: 600px) {
        padding: 40px 20px;
      }

      h2 {
        opacity: 1;
        color: rgba(31, 31, 31, 1);
        font-family: "HelveticaNeue-Medium";
        font-size: 40px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0px;
        text-align: center;
        margin-bottom: 12px;
      }

      p {
        opacity: 1;
        color: rgba(156, 156, 156, 1);
        font-family: "HelveticaNeue-Medium";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0px;
        text-align: center;
        margin: 12px 0 83px;

        @media only screen and (max-width: 600px) {
          margin: 20px;
        }
      }

      .pricingCard {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 24px;
        margin-bottom: 41px;

        @media only screen and (max-width: 1024px) {
          flex-direction: column;
        }

        .otherBusinessPlanContainer {
          width: 320px;
          margin: 0;

          @media only screen and (max-width: 1024px) {
            width: 100%;
            min-height: auto;
          }

          .businessPlanItem {
            align-items: center;
          }

          .BasicPlan {
            padding: 30px 24px;
          }

          .BusinessPlanHeader {
            padding: 0;
            margin-bottom: 40px;
          }

          .mostPopular {
            opacity: 1;
            color: rgba(134, 180, 156, 1);
            font-family: "HelveticaNeue-Italic";
            font-size: 16px;
            font-weight: 400;
            font-style: italic;
            letter-spacing: 0px;
            text-align: left;
            margin-left: 30px;
          }

          .hide {
            color: transparent;
            @media only screen and (max-width: 1024px) {
              display: none;
            }
          }

          .EnterprisePlan {
            margin: 0;
          }

          .BusinessPlanBody {
            margin-bottom: 85px;
          }
        }

        .wrapp {
          width: 30%;

          @media only screen and (max-width: 1024px) {
            width: 100%;
          }

          .popular {
            opacity: 1;
            color: rgba(134, 180, 156, 1);
            font-family: "HelveticaNeue-Italic";
            font-size: 16px;
            font-weight: 400;
            font-style: italic;
            letter-spacing: 0px;
            text-align: left;
            margin-left: 30px;
          }

          .box {
            width: 100%;
            margin: auto;
            padding: 30px 25px;

            .price {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-around;

              @media only screen and (max-width: 600px) {
                align-items: baseline;
              }

              .plan {
                width: 75%;
                @media only screen and (max-width: 600px) {
                  width: 50%;
                }

                span {
                  opacity: 1;
                  color: rgba(0, 0, 0, 1);
                  font-family: "HelveticaNeue";
                  font-size: 16px;
                  font-weight: 400;
                  font-style: normal;
                  letter-spacing: 0px;
                  text-align: left;
                }

                h3 {
                  opacity: 1;
                  color: rgba(0, 0, 0, 1);
                  font-family: "HelveticaNeue-Medium";
                  font-size: 24px;
                  font-weight: 500;
                  font-style: normal;
                  letter-spacing: 0px;
                  text-align: left;
                  margin: 0;
                  margin-top: 7px;
                }
              }

              .bymonth {
                width: 25%;
                text-align: right;

                @media only screen and (max-width: 600px) {
                  width: 50%;
                }

                span {
                  opacity: 1;
                  color: rgba(0, 0, 0, 1);
                  font-family: "HelveticaNeue";
                  font-size: 16px;
                  font-weight: 400;
                  font-style: normal;
                  letter-spacing: 0px;
                  text-align: right;
                }

                h4 {
                  opacity: 1;
                  color: rgba(31, 31, 31, 1);
                  font-family: "HelveticaNeue-Medium";
                  font-size: 24px;
                  font-weight: 500;
                  font-style: normal;
                  letter-spacing: 0px;
                  text-align: right;
                  margin: 0;
                  margin-top: 7px;
                }
              }
            }

            ul {
              margin-top: 40px;
              margin-bottom: 0;
              list-style: none;
              padding: 0;

              li {
                display: flex;
                gap: 16px;
                margin-bottom: 30px;
              }
            }

            .getStarted {
              width: 100%;
              height: 40px;
              border-radius: 23px;
              box-shadow: 0px 2px 4px rgba(217, 217, 217, 1);
              opacity: 1;
              background-color: rgba(97, 132, 157, 1);
              border: none;
              opacity: 1;
              color: rgba(255, 255, 255, 1);
              font-family: "HelveticaNeue-Medium";
              font-size: 14px;
              font-weight: 500;
              font-style: normal;
              letter-spacing: 0px;
              text-align: center;
              margin-top: 45px;

              @media only screen and (max-width: 600px) {
                margin-top: 5px;
              }
            }

            .getStartedAdvance {
              width: 100%;
              height: 40px;
              border-radius: 23px;
              box-shadow: 0px 2px 4px rgba(217, 217, 217, 1);
              opacity: 1;
              background-color: rgba(134, 180, 156, 1);
              border: none;
              opacity: 1;
              color: rgba(255, 255, 255, 1);
              font-family: "HelveticaNeue-Medium";
              font-size: 14px;
              font-weight: 500;
              font-style: normal;
              letter-spacing: 0px;
              text-align: center;
              margin-top: 45px;

              @media only screen and (max-width: 600px) {
                margin-top: 5px;
              }
            }

            .getStartedEnterprise {
              width: 100%;
              height: 40px;
              border-radius: 23px;
              box-shadow: 0px 2px 4px rgba(217, 217, 217, 1);
              opacity: 1;
              background-color: rgba(235, 194, 99, 1);
              border: none;
              opacity: 1;
              color: #1f1f1f;
              font-family: "HelveticaNeue-Medium";
              font-size: 14px;
              font-weight: 500;
              font-style: normal;
              letter-spacing: 0px;
              text-align: center;
              margin-top: 45px;

              @media only screen and (max-width: 600px) {
                margin-top: 5px;
              }
            }
          }

          #basic {
            border-radius: 24px;
            border: 1px solid rgba(97, 132, 157, 1);
            opacity: 1;
            background-color: rgba(244, 244, 243, 1);
          }
          #advance {
            border-radius: 24px;
            border: 1px solid rgba(134, 180, 156, 1);
            opacity: 1;
            background-color: rgba(244, 244, 243, 1);
          }

          #enterprise {
            border-radius: 24px;
            border: 1px solid rgba(235, 194, 99, 1);
            opacity: 1;
            background-color: rgba(244, 244, 243, 1);
          }
        }
      }
    }
  }

  .contactUs {
    margin: 50px 0;

    @media only screen and (max-width: 600px) {
      width: 90%;
      margin: auto;
      margin-top: 50px;
    }

    p {
      opacity: 1;
      color: rgba(156, 156, 156, 1);
      font-family: "HelveticaNeue";
      font-size: 18px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0px;
      text-align: center;
      margin: 0;
    }

    a {
      text-transform: uppercase;
      text-decoration: underline;
      color: #61849d;
    }
  }
}
