.practitionersWrappper {
  .headingWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: baseline;
      gap: 10px;
    }

    .smallButtonContainers {
      width: 190px;
    }
  }

  .oval-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 250px);
  }

  .dataTableContainer {
    margin-top: 60px;
  }

  .patientInfo,
  .patientTable {
    @media only screen and (max-width: 992px) {
      .dataRow {
        flex-direction: row;
        padding: 10px !important;
      }
    }
  }

  .ConfirmPopUpContent {
    display: flex;
    flex-direction: column;
  }

  .popUpPay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 3;

    .popUpPayBackdrop {
      background-color: rgb(97, 132, 157);
      opacity: 0.6;
      width: 100%;
      height: 100%;
    }

    .popUpContent {
      border-radius: 27px;
      width: 509px;
      height: 484px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      opacity: 1;
      background-color: rgba(238, 251, 255, 1);
      padding: 50px;

      @media only screen and (max-width: 600px) {
        width: 90%;
        height: fit-content;
        padding: 20px;
      }

      h1 {
        opacity: 1;
        color: rgba(31, 31, 31, 1);
        font-family: "HelveticaNeue-Medium";
        font-size: 32px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        margin: 0;
      }

      h2 {
        opacity: 1;
        color: rgba(31, 31, 31, 1);
        font-family: "HelveticaNeue-Bold";
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 21px;
        margin: 30px 0 40px;

        @media only screen and (max-width: 600px) {
          margin: 30px 0;
        }
      }

      p {
        opacity: 0.960333;
        color: rgba(31, 31, 31, 1);
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 21px;
        margin: 30px 0;
        width: 95%;

        @media only screen and (max-width: 600px) {
          width: 100%;
          margin: 20px 0;
        }
      }

      .priceTag {
        border-radius: 10px;
        box-shadow: 4px 4px 11px rgba(97, 132, 157, 0.040538);
        opacity: 1;
        background-color: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 86px;
        opacity: 0.960333;
        color: rgba(31, 31, 31, 1);
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 21px;
        gap: 5px;

        span {
          opacity: 1;
          color: rgba(31, 31, 31, 1);
          font-family: "HelveticaNeue-Medium";
          font-size: 22px;
          font-weight: 700;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
        }
      }

      .popUpButtons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
        

        .smallButtonContainers {
          width: 150px;
          margin: 0;
        }

        .goBack {
          opacity: 1;
          color: rgba(31, 31, 31, 1);
          font-family: "HelveticaNeue-Medium";
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .empryState {
    display: flex;
    height: calc(100vh - 300px);
    align-items: center;
    justify-content: center;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        opacity: 1;
        color: rgba(156, 156, 156, 1);
        font-family: "HelveticaNeue-Italic";
        font-size: 20px;
        font-weight: 400;
        font-style: italic;
        letter-spacing: 0px;
        text-align: center;
        line-height: 27px;
        margin-bottom: 23px;
      }

      .smallButtonContainers {
        width: 200px;
        height: 32px;
        margin-right: 16px;
      }
    }
  }

  .mobileTable {
    margin-top: 20px;

    @media only screen and (min-width: 993px) {
      margin-right: 20px;
    }

    .topSortMobile {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .sort {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
          margin: 0;
          opacity: 1;
          color: rgba(156, 156, 156, 1);
          font-family: "HelveticaNeue";
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
        }
      }
    }

    .mobileWrapper {
      opacity: 1;
      background-color: rgba(244, 244, 243, 1);
      padding: 12px;
      margin-bottom: 12px;

      .patientName {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(156, 156, 156, 0.2);
        padding-bottom: 12px;

        h3 {
          margin: 0;
          opacity: 1;
          color: rgba(31, 31, 31, 1);
          font-family: "HelveticaNeue-Bold";
          font-size: 16px;
          font-weight: 700;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
        }
      }

      .seeMoreMob {
        padding-top: 12px;
      }
    }

    .tableFooterLeft {
      margin-top: 20px;
    }
  }
}
