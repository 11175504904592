.ColorScheme {
  display: flex;

  @media only screen and (max-width: 992px) {
    gap: 20px;
  }
}

.themePicker {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width, height 0.25s ease-in-out;
}

.selectedPicker {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width, height 0.25s ease-in-out;
}

.themeContainer {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 64px;

  @media only screen and (max-width: 992px) {
    margin: 0;
  }
}

.invisibleIcon {
  visibility: hidden;
}

.visibleIcon {
  visibility: visible;
  transition: visibility 0.25s ease-in-out;
}
