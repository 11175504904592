.Stepper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;
}

.stepperCircle {
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 16px;
  justify-content: center;
  align-items: center;
}

.stepperText {
  margin-top: 10px;
  font-size: 14px;
  color: #1F1F1F;
  max-width: 121px;
  text-align: center;
}


@media (max-width:641px) { // mobile
  .stepperText{
    font-size: 12px;
    text-align: center;
  }

}
