.wrapper {
  //   width: 600px;
  //   margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 10px;
}

.accordion-wrapper {
  // position: relative;
  & + * {
    margin-top: 0.5em;
  }
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 22px;
  border-radius: 25px;
  opacity: 1;
  // background-color: rgba(229, 236, 241, 1);
  margin-bottom: 20px;
  opacity: 1;
  color: rgba(31, 31, 31, 1);
  font-family: "HelveticaNeueMedium", serif !important;
  font-size: 24px;

  text-align: left;
  width: fit-content;
  height: 50px;

  //   @media only screen and (max-width: 600px) {
  //     font-size: 16px;
  //   }

  &::before {
    content: url("https://chiropracticoutcomes.com/assets/ic_dropdown-down.svg");
    width: 46px;
    border-radius: 50%;
    height: 46px;
    border: 1px solid #61849d;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    // border-left: 5px solid transparent;
    // border-right: 5px solid transparent;
    // border-top: 5px solid currentColor;
  }

  &:hover,
  &.open {
    color: #61849d;
  }

  &.open {
    &::before {
      content: url("https://chiropracticoutcomes.com/assets/ic_dropdown-up.svg");

      //   border-top: 0;
      //   border-bottom: 5px solid;
    }
  }

  &.accordion-title-child {
    font-size: 16px;
    height: 40px;
  }
}

.accordion-content {
  padding: 1em 1.5em;
}

.surveyTitle {
  .TextInputLabel {
    font-size: 24px;
    line-height: 28px;
    color: #212529;
    margin-left: 0;
    opacity: unset;
  }

  // .TextInputContainer {
  //   margin-top: 40px;
  // }
}

.accordion-child {
  //margin-left: 20px;
}

.accordion-not-clickable-item {
  position: absolute;
  background-color: #4c4747;
  color: #eeeded;
  font-size: 10px;
  padding: 3px 5px;
  opacity: 0.9;
  z-index: 2;
  left: 100px;
  bottom: 2px;
}
