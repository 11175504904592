.SurveyHero {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  @media only screen and (max-width: 600px) {
    height: 100%;
    padding: 150px 0 50px;
  }
}

.centeredDiv {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding-top: 100px;
}

.text-only {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.left-text {
  align-items: start;
}

.helloDiv {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  width: auto;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
}

.headline {
  margin-top: 30px;
  text-align: center;
  font-size: 56px;
  opacity: 1;
  color: #1f1f1f;
  font-weight: 400;
  width: 700px;
  font-family: NewAthenaRegular, serif;
}

.text {
  margin-top: 27px;
  font-size: 16px;
  font-weight: 400;
  width: 480px;
  text-align: center;
}

.text-left {
  text-align: left;
}

.ctaContainer {
  width: 240px;
  height: 45px;
  margin-top: 50px;
  text-transform: uppercase;
}

.heroForm {
  // margin-top: 38px;
  margin-top: 30px;
}

.paragraphLine {
  opacity: 1;
  color: rgba(122, 126, 130, 1);
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;

  @media (max-width: 1200px) {
    width: 90%;
    text-align: center;
  }
}

@media (max-width: 641px) {
  // mobile

  .centeredDiv {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 0;
  }

  .helloDiv {
    font-size: 14px;
  }

  .headline {
    margin-top: 20px;
    font-size: 32px;
    width: calc(100vw - 64px);
    line-height: 1.2;
  }

  .text {
    margin-top: 16px;
    font-size: 14px;
    width: calc(100vw - 64px);
  }

  .ctaContainer {
    width: calc(100vw - 64px);
    margin-top: 32px;
  }

  .heroForm {
    width: calc(100vw - 64px);
  }
}

@media only screen and (min-width: 642px) and (max-width: 992px) {
  .heroForm {
    width: 60% !important;
  }
}
