.dataTableContainer {
  margin-top: 60px;

  @media only screen and (max-width: 992px) {
    .dataTable {
    }
  }
}

@media only screen and (max-width: 992px) {
  .patientTable {
    .dataTable {
      .dataRow {
        .lastCellPatients {
          display: flex;
          padding-right: 10px;
          text-align: left;
        }
      }
    }
  }
}
