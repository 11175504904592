.practitionersProfileWrap {
  .profileContent {
    margin-top: 80px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .stastContent {
    margin-top: 80px;

    .sectionChange {
      width: 75%;

      @media only screen and (max-width: 992px) {
        width: 100%;
      }

      .content {
        margin: 40px 0 80px;

        @media only screen and (max-width: 1200px) {
          margin: 40px 0;
        }

        .rateSection {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 50px;

          @media only screen and (max-width: 1200px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
          }
        }

        .starStyle {
          border-radius: 7px;
          display: flex;
          flex-direction: row;
          gap: 24px;

          @media only screen and (max-width: 1200px) {
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 5px;
          }

          img {
            border-radius: 7px;
            opacity: 1;
            background-color: rgba(241, 237, 229, 1);
            padding: 20px;
          }
        }

        .rateStat {
          display: flex;
          flex-direction: column;
          width: 61px;
          opacity: 1;
          color: rgb(122, 126, 130);
          font-family: "HelveticaNeue";
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;

          span {
            opacity: 1;
            color: rgba(97, 132, 157, 1);
            font-size: 30px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: 0.94px;
            text-align: left;
          }
        }

        .numberStats {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 50px;

          @media only screen and (max-width: 1200px) {
            flex-direction: column !important;
            align-items: flex-start !important;
            gap: 20px !important;
          }

          .numberStatsContent {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .ratingStats {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 14px;

              @media only screen and (max-width: 1200px) {
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 4px;
              }

              span {
                border-radius: 7px;
                opacity: 1;
                background-color: rgb(241, 237, 229);
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 1;
                color: rgba(31, 31, 31, 1);
                font-family: "Helvetica-Bold";
                font-size: 18px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: 0px;
                text-align: left;

                &.filled {
                  opacity: 1;
                  background-color: rgba(97, 132, 157, 1);
                  color: #fff;
                }
              }
            }

            .typeStatMob {
              display: block;
              margin-bottom: 10px;

              .severenessText {
                font-size: 14px;
                color: #494949;
              }

              @media only screen and (min-width: 1200px) {
                display: none;
              }
            }

            .typeStat {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;

              @media only screen and (max-width: 1200px) {
                display: none;
              }

              p {
                margin: 0;
                opacity: 1;
                color: rgba(73, 73, 73, 1);
                font-family: "HelveticaNeue";
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: 0px;
                text-align: right;
                line-height: 16px;
              }
            }
          }
        }

        .statisticsPeople {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          gap: 7px;

          h3 {
            opacity: 1;
            color: rgba(97, 132, 157, 1);
            font-family: "HelveticaNeue-Medium";
            font-size: 30px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: 0.94px;
            text-align: left;
          }

          span {
            opacity: 1;
            color: rgba(122, 126, 130, 1);
            font-family: "HelveticaNeue";
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
            text-align: left;
          }
        }
      }

      .wrapper {
        .accordionContent {
          margin-bottom: 20px;

          .accordionTop {
            display: flex;
            align-items: flex-start;
            gap: 8px;

            h2 {
              opacity: 1;
              color: rgba(73, 73, 73, 1);
              font-family: "HelveticaNeue-Medium";
              font-size: 16px;
              font-weight: 500;
              font-style: normal;
              letter-spacing: 0px;
              text-align: left;
              margin: 0;
            }

            .starStyle {
              display: flex;
              align-items: center;
              gap: 4px;

              img {
                width: 10px;
                height: 10px;
              }
            }
          }

          .accordionBottom {
            margin-top: -2px;
            p {
              opacity: 1;
              color: rgba(122, 126, 130, 1);
              font-family: "HelveticaNeue";
              font-size: 16px;
              font-weight: 400;
              font-style: normal;
              letter-spacing: 0px;
              text-align: left;
              margin: 0;
            }
          }
        }
      }

      .fiveStar {
        .accordion-title {
          z-index: 999;
          position: inherit;
        }
      }
    }
  }
}
