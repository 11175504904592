.local-bootstrap {
  @import "~bootstrap/scss/bootstrap";
}

.landing-main {
  width: 1440px;
  // border: 1px solid rgb(214, 214, 214);
}

.headerNavigation {
  padding: 31px 100px;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .headerNavigationLogo {
    img {
      width: 134px;
    }
  }

  .headerNavigationContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;

    .leftContent {
      padding-right: 25px;
      border-right: 1px solid #cfcfcf;

      ul {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 50px;
        padding: 0;
        margin: 0;

        a {
          font-size: 18px;
          color: #505050;
          text-decoration: none;

          &:hover {
            color: #61849d;
          }
        }

        .active {
          color: #61849d;
        }
      }
    }

    .rightContent {
      display: flex;
      align-items: center;
      gap: 34px;

      a {
        font-size: 18px;
        text-decoration: none;
        color: #61849d;
      }

      button {
        padding: 15px 33px;
        border: none;
        border-radius: 10px;
        background: #5e93ba;
        font-size: 16px;
        color: #fff;

        &:hover {
          color: #000000;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    display: none;
  }
}

.headerNavigationMobile {
  display: none;

  .headerNavigationMobileLogo {
    width: 101px !important;
    height: 101px !important;
  }

  @media only screen and (max-width: 992px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }
}

.mobileMenuOverlay {
  position: fixed;
  left: 0;
  backdrop-filter: blur(6px);
  height: 100%;
  z-index: 999;
  padding: 0;

  .menuMobileopen {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.215745);
    opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    width: 80%;
    float: right;
    height: 100%;
    padding: 28px 36px;

    .menuMobileopenHeader {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 58px;

      .companyLogo {
        width: 100px !important;
        height: auto;
      }
    }

    .buttonBookMobile {
      width: 100%;
      height: 44px;
      border: none;
      border-radius: 10px;
      background: #5e93ba;
      font-size: 16px;
      color: #fff;
      margin-bottom: 58px;
    }

    .menuItems {
      display: flex;
      flex-direction: column;
      gap: 39px;
      list-style-type: none;
      padding: 0;
      margin: 0;
      padding-bottom: 39px;
      border-bottom: 1px solid #ded6c7;

      li {
        a {
          font-size: 18px;
          color: #505050;
          text-decoration: none;
        }
      }
    }

    .menuItemsAuth {
      display: flex;
      flex-direction: column;
      gap: 39px;
      list-style-type: none;
      padding: 0;
      margin: 0;
      padding-top: 39px;

      li {
        a {
          font-size: 18px;
          color: #505050;
          text-decoration: none;
        }
      }
    }
  }
}

.glow {
  opacity: 1;
  background-image: radial-gradient(
    rgba(94, 147, 186, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: blur(23.811966px);
  position: absolute;
  width: 70%;
  height: 70%;
  top: 7%;
  left: 15%;
}

.glow3 {
  opacity: 1;
  background-image: radial-gradient(
    rgba(94, 147, 186, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: blur(23.811966px);
  position: absolute;
  width: 70%;
  height: 70%;
  top: -3%;
  left: 23%;
}

.landing-section {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.landing-basic-font {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
}

.landing-bolder-font {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.landing-thiner-font-tall {
  font-weight: 300;
  font-size: 56px;
  line-height: 61px;
  font-family: "HelveticaNeueLight", serif;
}

.landing-thin-font {
  font-weight: 300;
  font-size: 40px;
  font-family: "HelveticaNeueLight", serif;
}

.landing-header {
  margin: 0;
  padding: 0;
  height: fit-content;
  align-items: center;
}

.landing-top-left-div {
  margin-left: 100px;
  margin-top: 38px;
  margin-bottom: 31px;
  height: fit-content;
  padding: 0;
}

.landing-top-right-div {
  height: fit-content;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
}

.landing-dark-text-top {
  /* width: 134px !important; */
  height: 49px !important;
  margin: 0px;
  text-align: center;
  font-weight: 500;
  font-size: 23px;
  float: left;
  margin-left: 88px;
}

.landing-word-top {
  display: block;
  margin: 0;
  padding: 0;
  color: #1f1f1f;
}

.landing-word-bottom {
  display: block;
  margin: 0;
  padding: 0;
  color: #5e93ba;
}

.landing-top-right-button {
  margin-right: 100px;
  margin-top: 38px;
  margin-bottom: 31px;
  color: #ffffff;
  width: 190px !important;
  height: 48px !important;
  background-color: #5e93ba !important;
  border-radius: 10px;
}

.landing-second-right-button {
  margin-top: 38px;
  margin-bottom: 31px;
  color: #5e93ba;
  width: 190px !important;
  height: 48px !important;
  border: 1px solid #5e93ba !important;
  border-radius: 10px;
  text-transform: uppercase;

  &:hover {
    background-color: #5e93ba;
    color: #fff;
  }
}

.landing-section-one {
  display: flex;
}

@media only screen and (min-width: 1440px) {
  .landing-section-one {
    max-width: 1440px;
    margin: auto;
  }
}

.landing-section-one-left {
  margin-left: 100px;
  padding: 0;
  display: inline;
  width: fit-content;
}

.landing-section-one-right {
  width: auto;
  position: relative;
}

.landing-big-letters-section-one {
  width: 540px !important;
  height: 183px !important;
  color: #1f1f1f;
  text-align: left;
  margin-bottom: 16px;
  margin-top: 65px;
  padding: 0;
}

.landing-matters {
  font-weight: 500;
  font-size: 56px;
  line-height: 61px;
  color: #1f1f1f;
  text-align: left;
  font-family: "HelveticaNeueMedium", serif;
}

.landing-dot {
  color: #1f1f1f;
  text-align: left;
}

.landing-small-letters-section-one {
  width: 485px !important;
  height: 46px !important;
  color: #494949;
  text-align: left;
  padding: 0;
  margin-bottom: 40px;
}

.landing-section-one-button {
  color: #ffffff;
  width: 240px !important;
  height: 48px !important;
  background-color: #5e93ba !important;
  border-radius: 10px;
}

.landing-herephoto {
  margin-top: 78px;
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
  /* margin-bottom: 128px; */
}

.landing-section-two {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1440px) {
  .landing-section-two {
    max-width: 1440px;
    margin: auto;
  }
}

.landing-section-two-left {
  padding: 0;
  width: auto;
  position: relative;
}

.landing-tablet {
  /* margin-top: 87px; */
  margin-right: 75px;
  margin-left: 179px;
  height: 536px !important;
  position: relative;
  z-index: 3;
}

.landing-section-two-right {
  width: 540px;
}

.landing-section-two-text {
  margin-right: 180px;
  margin-left: 0px;
  /* margin-top: 205px; */
}

.landing-section-two-text-one {
  line-height: 54px;
  color: #1f1f1f;
}

.landing-section-two-text-two,
.landing-section-two-text-three {
  color: #494949;
  margin-top: 16px;
}

.landing-section-three-top {
  margin-top: 86px;
}

.landing-section-three,
.landing-section-eight {
  background: linear-gradient(270deg, #b3d2e9 0%, #cfe3f1 25%, #e7f1f8 100%);
  padding: 0 !important;
}

.landing-section-three-top-text {
  width: 601px !important;
  height: 98px !important;
  margin-left: auto;
  margin-right: auto;
}

.landing-benefits {
  font-weight: 500;
  font-size: 40px;
  line-height: 49px;
  color: #1f1f1f;
  text-align: center;
  font-family: "HelveticaNeueMedium", serif;
}

.landing-section-three-top-letters {
  line-height: 49px;
  color: #1f1f1f;
  text-align: center;
  display: block;
}

.landing-section-three-bottom-letters {
  line-height: 49px;
  color: #1f1f1f;
  text-align: center;
  display: block;
}

.landing-section-three-button,
.landing-section-four-button,
.landing-section-seven-button,
.landing-section-eight-button {
  margin-top: 40px;
  color: #ffffff;
  width: 241px !important;
  height: 50px !important;
  background-color: #5e93ba !important;
  border-radius: 10px;
}

.landing-section-three-bottom-text {
  margin-top: 16px;

  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #494949;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.landing-section-three-bottom-text p {
  width: 480px !important;
  height: 66px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 0;
}

.landing-section-three-button,
.landing-section-four-button,
.landing-section-seven-button {
  margin-left: auto;
  margin-right: auto;
}

.landing-section-three-bottom {
  margin-top: 81px;
  // margin-left: 0px;
  // margin-right: 0px;
}

@media only screen and (min-width: 1440px) {
  .landing-section-three-bottom {
    max-width: 1440px;
    margin: auto;
    margin-top: 81px;
  }
}

.landing-section-three-left {
  display: inline-block;
  width: auto;
  position: relative;
}

.landing-section-three-right {
  display: inline-block;
  width: auto;
  margin-left: 60px;
  margin-bottom: 228px;
}

.landing-laptop2 {
  height: 410px !important;
  /* width: 678px; */
  margin-top: 115px;
  margin-left: 100px;
  margin-right: 0px;
  position: relative;
  z-index: 2;
}

.glow2 {
  opacity: 1;
  background-image: radial-gradient(
    rgba(94, 147, 186, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: blur(23.811966px);
  position: absolute;
  width: 70%;
  height: 70%;
  top: 7%;
  left: 22%;
}

.landing-panel {
  flex-direction: column;
  width: 480px;
  min-height: 62px;
  height: auto !important;
  background-color: #fbfbfb;
  padding-left: 47px;
  border-radius: 15px;
  margin-bottom: 16px;
  margin-right: 120px;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.landing-panel-text {
  width: 100% !important;
}

.landing-panel-body {
  width: 100%;
  margin: 0;
  padding: 0;
}

.landing-rotate {
  transform: rotate(180deg);
}

.landing-panel-body-text {
  margin-top: 16px;
  margin-right: 115px;
}

.landing-panel-open {
  height: 180px;

  display: inline-block;
}

.landing-panel-two-lines {
  height: 80px;
}

.landing-panel-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #494949;
}

.landing-panel-text-head {
  font-family: "HelveticaNeueMedium", serif;
  line-height: 26px;
  margin-top: 18px;
}

.landing-panel-no-body {
  display: flex;
  align-items: center;
}

.landing-panel-no-body {
  margin: 0;
  font-weight: 500;
  line-height: 26px;
  color: #1f1f1f;
}

.landing-panel-text-body {
  width: 319px;
  height: 88px;
}

.landing-fa-angle-down,
.landing-fa-angle-up {
  width: 12px;
  height: 8px;
  margin-right: 41px;
  color: #1f1f1f;
  float: right;
  transform: rotate(0deg);
  transition: transform 0.25s ease-in-out;
}

.landing-panel-text-two-lines {
  width: 270px;
  height: 46px;
  line-height: 23px;
}

.landing-section-four {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1440px) {
  .landing-section-four {
    max-width: 1440px;
    margin: auto;
  }
}

.landing-section-four-main {
  /* margin-top: 118px; */
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 93px; */
  border-radius: 38px;
  background-color: #f5f5f5;
}

.landing-section-four-top {
  margin-top: 57px;
  margin-left: auto;
  margin-right: auto;
}

.landing-section-four-top-text {
  margin: 0;
  padding: 0;
  line-height: 61px;
  color: #1f1f1f;
  text-align: center;
  /* margin-left: auto;
  margin-right: auto; */
}

.landing-chiro-span {
  font-family: "HelveticaNeueMedium", serif;
}

.landing-section-four-middle {
  margin-top: 61px;
  margin-left: auto;
  margin-right: auto;
}

.landing-section-four-item {
  display: inline-block;
  width: auto;
  text-align: center;
}

.landing-section-four-item-middle {
  margin-left: 103px;
  margin-right: 103px;
}

.landing-section-four-circule {
  background-color: #ddeffd;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  padding: 0;

  font-weight: 500;
  font-size: 21px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-section-four-title {
  margin-top: 15px;
  padding: 0;
  font-family: "HelveticaNeueLight", serif;
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  color: #1f1f1f;
}

.landing-section-four-body {
  margin-top: 15px;
  padding: 0;

  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #494949;
  width: 319px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.landing-section-four-button-margin {
  margin-top: 56px;
  margin-bottom: 64px;
}

.landing-section-five {
  background: linear-gradient(90deg, #e7f1f8 100%, #cfe3f1 25%, #b3d2e9 0%);
  padding: 0 !important;
}

@media only screen and (min-width: 1440px) {
  .landing-section-wrap {
    max-width: 1440px;
    margin: auto;

    .landing-section-eight-right {
      width: 100%;
    }
  }
}

.landing-section-wrap {
  display: flex;
}

@media only screen and (min-width: 1440px) {
  .landing-section-wrap {
    max-width: 1440px;
    margin: auto;
  }
}

.landing-section-five-left {
  margin-top: 81px;
  margin-left: 120px;
  margin-bottom: 109px;
  display: inline-block;
  width: auto;
}

.landing-laptop-foto {
  height: 678px;
}

.landing-section-five-right {
  margin-top: 81px;
  margin-left: 92px;
  margin-bottom: 109px;
  display: inline-block;
  width: auto;
}

.landing-section-five-text-title {
  margin-top: 23px;
  text-align: left;
  padding: 0;
  line-height: 54px;
  color: #1f1f1f;
  width: 590px;
}

.landing-section-five-span {
  font-family: "HelveticaNeueMedium", serif;
}

.landing-section-five-text-body {
  margin-top: 16px;
  text-align: left;
  padding: 0;
  color: #494949;
  width: 590px;
}

.landing-section-five-text-read-more {
  margin-top: 30px;
  text-align: left;
  padding: 0;
  color: #61849d;
  width: 112px;
}

.landing-section-six {
  margin-top: 118px;
}

.landing-section-six-top {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.landing-section-six-text {
  width: 969px;
  padding: 0;
  line-height: 41px;
  color: #1f1f1f;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
}

.landing-section-six-bottom {
  height: 608px !important;
  height: auto;
  width: 1081px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  vertical-align: middle;
}

.landing-section-six-video {
  width: auto;
  vertical-align: middle;

  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-section-seven {
  /* margin-top: 182px; */
  display: flex;
  align-items: center;
}

.landing-section-six-bottom {
  margin-bottom: 182px;
}

.landing-section-six {
  display: block;
}

.landing-section-six-top {
  margin-bottom: 50px;
  display: block;
}

.landing-section-seven-top {
  margin-left: auto;
  margin-right: auto;
}

.landing-section-seven-text {
  width: 702px;
  height: 48px;
  line-height: 41px;
  color: #1f1f1f;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
}

.landing-section-seven-middle {
  margin-top: 55px;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  justify-content: center;
}

.landing-section-seven-item {
  width: 420px;
  height: 438px;
  margin-right: 30px;
  padding-top: 35px;
  padding-left: 0;
  padding-right: 0;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.landing-section-seven-item-top {
  height: 90px;
  display: flex;
  align-items: center;
  margin: 0;
}

.landing-section-seven-face {
  /* width: 90px; */
  height: 90px;
  margin-right: 24px;
  margin-left: 36px;
  display: inline-block;
  width: auto;
  padding: 0;
  border-radius: 50%;
}

.landing-section-seven-bio {
  display: inline-block;
  width: auto;
  padding: 0;
}

.landing-section-seven-title {
  font-weight: 400;
  font-size: 24px;
  /* line-height: 24px; */
  color: #1f1f1f;
  margin-bottom: 0px;
  display: block;
  width: auto;
}

.landing-section-seven-position {
  color: #61849d;
  /* margin-top:8px; */
  margin-bottom: 0px;
  letter-spacing: -0.35px;
  display: block;
  width: auto;
  font-family: "HelveticaNeueMedium", serif;
}

.landing-section-seven-item-bottom {
  margin-top: 32px;
  width: 360px;
  margin-left: 36px;
}

.landing-section-seven-bottom-text {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  color: #1f1f1f;
  /* margin-bottom:0px; */
  padding: 0;
  font-family: "HelveticaNeueLight", serif;
}

.landing-section-seven-button-margin {
  margin-top: 32px;
}

.landing-section-eight {
  margin-top: 182px;
  padding-top: 85px !important;
  padding-left: 120px !important;
  padding-right: 175px !important;
}

.landing-section-eight-left {
  width: auto;
  padding: 0;
}

.landing-section-eight-left-title {
  line-height: 61px;
  color: #1f1f1f;
  margin-bottom: 22px;
  width: auto;
}

.landing-section-eight-left-body {
  color: #494949;
  width: 360px;
}

.landing-section-eight-right {
  width: auto;
  margin-left: 180px;
  padding: 0;
}

.landing-section-eight-lable {
  font-weight: 400;
  font-size: 14px;
  color: #1f1f1f;
  width: auto;
  margin-bottom: 15px;
  margin-left: 22px;
}

.landing-section-eight-field {
  border-radius: 10px;
  background-color: #ffffff;
  width: 600px;
  height: 50px;
  padding-left: 35px;

  margin-bottom: 25px;

  font-weight: 400;
  font-size: 14px;
  color: #1f1f1f;
}

.landing-field-one {
  border: 1px solid #61849d;
}

.landing-field-two,
.landing-field-three {
  font-weight: 400;
  font-size: 14px;
  color: #9c9c9c;
}

.landing-field-three {
  height: 156px;
}

/* New changes */
.landing-text-area-style {
  display: flex;
  flex-direction: column;
}

.landing-text-area-style textarea {
  padding-top: 18px;
  resize: none;
}

.landing-section-eight-button {
  width: 335px !important;
}

.landing-section-eight-button-margin {
  margin-top: 48px;
  margin-bottom: 84px;
  border: none !important;
}

.landing-section-nine {
  background-color: #f5f5f5;
}

.landing-section-nine-icon {
  width: 40px;
  height: 40px;
  margin-left: 16px;
}

.landing-section-nine-div {
  display: flex;
  align-items: end;
  margin-right: 100px;
}

.landing-section-nine-right {
  margin-left: auto;
  display: inline-block;
}

.landing-section-nine-text {
  display: inline-block;
  text-align: left !important;
}

.landing-section-nine-bottom {
  margin-left: auto;
  margin-right: auto;
}

.landing-section-seven-span {
  font-family: "HelveticaNeueMedium", serif;
  font-size: 40px;
}

.landing-privacy {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #9c9c9c;
  margin-top: 7px;
  cursor: pointer;
}

.landing-line {
  /* width:100%; */
  height: 2px;
  text-align: left;
  margin-left: 100px;
  margin-right: 100px;
  color: #ded6c7;
  background-color: #ded6c7;
  border-width: 0;
}

.landing-text-nine {
  color: #61849d;
}

.landing-there-is-no {
  font-weight: 500 !important;
}

textarea::placeholder {
  color: #9c9c9c !important;
}

.landing-panel-head {
  width: 100%;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 600px) {
  .landing-main {
    width: auto;
    padding: 0;
  }

  .landing-dark-text-top {
    margin-left: 0;
    // margin-top: 35px;
  }

  .glow {
    top: -4%;
  }

  .glow3 {
    left: 15%;
  }

  .landing-bolder-font {
    font-size: 14px;
    line-height: 19px;
  }

  .landing-basic-font {
    /* font-size: 18px;
    line-height: 22px; */

    font-size: 16px;
    line-height: 23px;
  }

  .landing-section-two-text-two,
  .landing-section-two-text-three {
    font-size: 14px !important;
    line-height: 18px !important;
    color: #494949 !important;
  }

  .landing-there-is-no {
    font-weight: 500 !important;
  }

  .landing-panel-no-body,
  .landing-panel-text-head {
    font-size: 16px;
    line-height: 26px;
  }

  .landing-panel-text-two-lines {
    line-height: 24px;
  }

  .landing-section-four-top {
    margin-top: 46px;
  }

  .landing-section-four-middle,
  .landing-big-letters-section-one {
    margin-top: 0;
  }

  .landing-section-five-left {
    padding: 0px !important;
    background-color: transparent;
    height: auto !important;
    width: auto !important;
    margin: 0px;
  }

  .landing-small-letters-section-one {
    margin-bottom: 51px;
  }

  .landing-section-four-item {
    margin-top: 50px;
  }

  .landing-section-four-title {
    font-weight: 300 !important;
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 0px;
  }

  .landing-section-four-body,
  .landing-section-three-bottom-text {
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    margin-top: 8px;
  }

  .landing-section-three-bottom-text {
    margin-top: 16px !important;
  }

  .landing-section-seven-no-margin {
    margin-bottom: 0px !important;
  }

  .landing-matters,
  .landing-thiner-font-tall {
    font-size: 40px;
    line-height: 45px;
  }

  .landing-benefits {
    font-size: 32px;
    font-language-override: 36px;
  }

  .landing-section-four-button-margin {
    margin-top: 49px;
    margin-bottom: 61px;
  }

  .landing-section-five-text-title {
    margin-bottom: 24px !important;
  }

  .landing-section-five-text-body {
    margin-top: 16px;
    margin-bottom: 0px;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #494949 !important;
  }

  .landing-section-five-text-read-more {
    margin-top: 25px;
    display: block;
    font-size: 16px !important;
    cursor: pointer;
  }

  .landing-section-one-button {
    height: 40px !important;
    width: 176px !important;
  }

  .landing-thin-font {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 0px;
  }

  .landing-section-two-text-two {
    margin-top: 24px;
    margin-bottom: 0px;
  }

  .landing-section-two-text-three {
    margin-top: 8px;
  }

  .landing-section-three-top {
    margin-top: 57px;
  }

  .landing-section-four-button,
  .landing-section-seven-button,
  .landing-section-three-button {
    width: 176px !important;
    height: 40px !important;
  }

  .landing-section-seven-button {
    font-size: 14px;
    line-height: 19px;
  }

  .landing-section-three-button {
    margin-top: 32px;
  }

  .landing-section-three-bottom {
    margin-top: 87px;
  }

  .landing-section-header,
  .landing-section-one,
  .landing-section-two,
  .landing-section-three,
  .landing-section-four,
  .landing-section-five,
  .landing-section-six,
  .landing-section-seven,
  .landing-section-eight,
  .landing-section-nine {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto !important;
    width: auto !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-left: 0;
    margin-right: 0;
  }

  .landing-section-eight-left,
  .landing-section-eight-right {
    background-color: transparent;
    height: auto !important;
    width: auto !important;
    margin: 0px;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .landing-section-three-top,
  .landing-section-three-bottom,
  .landing-section-four-main,
  .landing-section-six-top,
  .landing-section-six-bottom,
  .landing-section-seven-middle,
  .landing-section-seven-bottom,
  .landing-section-nine-top,
  .landing-section-nine-bottom {
    background-color: transparent;
    height: auto !important;
    width: auto !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-left: 0;
    margin-right: 0;
  }

  .landing-section-three-left,
  .landing-section-eight-button,
  .landing-section-eight-button,
  .landing-panel-text-body,
  .landing-fa-angle-up {
    display: none;
  }

  .landing-collapse.landing-dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }

  .landing-section-four-middle,
  .landing-section-seven-middle {
    flex-direction: column;
  }

  .landing-section-seven-middle {
    margin-left: auto;
    margin-right: auto;
  }

  .landing-top-left-div {
    margin-bottom: 0px;
    margin-left: auto;
    width: auto !important;
    display: inline-block;
  }

  .landing-header {
    margin-bottom: 84px;
    display: flex;
    align-items: center;
  }

  .landing-top-right-div {
    margin-bottom: 0px;
    width: auto !important;
    display: inline-block;
  }

  .landing-top-right-button {
    margin-bottom: 15px;
    margin-right: auto;
    width: 134px !important;
    height: 40px !important;
    margin-top: 10px;
  }

  .landing-second-right-button {
    margin-bottom: 0;
    margin-right: auto;
    width: 134px !important;
    height: 40px !important;
  }

  .landing-section-one-left {
    order: 2;
    padding: auto !important;
    margin: 0px;
  }

  .landing-section-one-right {
    order: 1;
    padding: 0 !important;
    margin: 0px;
  }

  .landing-herephoto {
    margin-top: 0;
    margin-bottom: 53px;
    position: relative;
    z-index: 2;
  }

  .landing-section-two {
    margin-top: 136px;
  }

  .landing-tablet,
  .landing-section-three-bottom-text p {
    height: auto !important;
  }

  .landing-section-two-left,
  .landing-section-two-right {
    width: auto !important;
    margin: 0px;
  }

  .landing-section-two-text,
  .landing-section-five-text-title {
    margin-top: 48px;
  }

  .landing-section-three {
    margin-top: 113px;
  }

  .landing-panel-open {
    width: 100% !important;
    height: 62px;
  }

  .landing-hide-arrow,
  .landing-fa-angle-down {
    display: block !important;
  }

  .landing-section-nine-right {
    margin: 0;
  }

  .landing-section-five-text-title,
  .landing-section-four-body,
  .landing-section-five-text-body,
  .landing-panel-text-body,
  .landing-section-eight-left-body,
  .landing-small-letters-section-one,
  .landing-section-two-right,
  .landing-section-eight-field,
  .landing-section-eight-button {
    width: auto !important;
  }

  .landing-panel {
    width: 100% !important;
    height: 62px;
    margin-right: 0px;
  }

  .landing-section-three-top-text,
  .landing-big-letters-section-one {
    width: auto !important;
    height: auto !important;
  }

  .landing-section-three-right {
    margin: 0px;
    margin-bottom: 104px;
    width: 100%;
  }

  .landing-panel-two-lines {
    height: 86px;
  }

  #last-panel {
    margin-bottom: 0px;
  }

  .landing-tablet,
  .landing-section-seven-button-margin {
    margin: 0px;
  }

  .landing-section-four-main {
    margin-top: 63px;
    margin-bottom: 93px;
  }

  .landing-section-four-main {
    background-color: #f5f5f5 !important;
    border-radius: 38px;
    padding-left: 17px !important;
    padding-right: 17px !important;
  }

  .landing-laptop-foto {
    margin-top: 38px;
    height: auto !important;
  }

  .landing-section-six {
    margin-top: 47px;
  }

  .landing-section-six-bottom {
    height: 176px !important;
    margin-top: 47px;
    margin-bottom: 0px;
  }

  .landing-section-seven {
    margin-top: 100px;
  }

  .landing-section-seven-middle,
  .landing-section-nine-top,
  .landing-section-three-bottom,
  .landing-section-eight-right,
  .landing-section-three-top {
    padding: 0 !important;
  }

  .landing-section-five-right {
    margin-top: 0px;
    margin-bottom: 94px;
    background-color: transparent;
    height: auto !important;
    width: auto !important;
    margin-left: 0;
    margin-right: 0;
  }

  .landing-section-six-top {
    margin-bottom: 0px;
  }

  .landing-section-seven-item-top {
    height: auto !important;
    margin-bottom: 21px;
  }

  .landing-section-seven-bio {
    display: block;
    width: 100%;
  }

  .landing-section-seven-title,
  .landing-section-seven-bottom-text {
    font-size: 18px;
  }

  .landing-section-seven-bottom {
    height: fit-content !important;
  }

  .landing-section-seven-position {
    font-size: 14px;
    margin-top: 6px;
  }

  .landing-section-seven-item-bottom {
    margin: 0px;
    width: auto !important;
    margin-bottom: 27px;
  }

  .landing-section-seven-bottom {
    margin-left: auto;
    margin-right: auto;
  }

  .landing-section-seven-item {
    height: auto !important;
    padding: 30px;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
  }

  .landing-section-eight {
    margin-top: 131px;
    padding-top: 64px !important;
  }

  .landing-section-eight-left {
    text-align: center;
  }

  .landing-section-eight-left-body {
    margin-top: 16px;
  }

  .landing-section-eight-left-body {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 56px;
  }

  textarea::placeholder {
    color: #9c9c9c !important;
  }

  .landing-section-eight-field,
  .landing-section-nine-div {
    width: 100% !important;
  }

  .landing-section-eight-field {
    margin-bottom: 32px !important;
  }

  #msg {
    padding-right: 30px;
    color: #9c9c9c !important;
    margin: 0px;
    margin-bottom: 0px !important;
  }

  .landing-section-eight-button-margin {
    margin-top: 32px;
    margin-bottom: 96px;
  }

  .landing-section-eight-button {
    font-size: 16px !important;
    width: 197px !important;
    height: 50px !important;
  }

  .landing-top-left-div {
    margin-top: 56px;
  }

  #inquiry {
    display: block !important;
    margin-left: auto;
    margin-right: auto;
  }

  .landing-section-seven-face {
    margin-left: 0px;
    margin-bottom: 21px;
  }

  .landing-section-nine-div {
    margin-right: 0;
  }

  .landing-section-seven-text {
    height: auto !important;
    width: auto !important;
    text-align: center;
  }

  .landing-section {
    padding: 0px;
  }

  .landing-section-three,
  .landing-section-eight {
    padding: auto !important;
  }

  .landing-section-three-bottom-text p {
    width: auto !important;
    padding: auto !important;
  }

  .landing-section-seven-span {
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
  }

  .landing-section-nine-right {
    float: right;
  }

  #insta {
    margin-right: 0px !important;
  }

  .landing-section-nine-bottom {
    padding: 0px !important;
  }

  .landing-section-seven-middle {
    margin-top: 40px;
  }

  .landing-line {
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 55px;
    margin-bottom: 0px;
    color: #ded6c7;
  }

  .landing-section-two-text {
    margin-right: 0;
  }

  .landing-dark-text-top {
    font-size: 12px;
  }

  .landing-section-nine-text {
    font-size: 15px !important;
  }

  .landing-section-two-text-three {
    margin-bottom: 0px;
  }
}

.fa-angle-down {
  margin-right: 40px;
}

.rotated-arrow {
  transform: rotate(180deg);
  transition: transform 0.25s ease-in-out;
}

// New changes
.copyright {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-bottom: 15px;

  @media only screen and (max-width: 600px) {
    margin-top: 15px;
  }

  span {
    color: #9c9c9c;
  }

  p {
    margin: 0;
  }
}

.privacyTerms {
  margin: 20px 0;
  padding: 0 20px;
  opacity: 1;
  color: rgba(73, 73, 73, 1);
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;

  a {
    color: #61849d;
  }
}

.presentContainer {
  width: 240px;
  height: 45px;
  margin-top: 30px;
}

.surveyPolicy {
  padding-left: 36px;
  // width: 544px;
  text-align: center;
  margin: 0;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
