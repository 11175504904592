.tabContainer {
  display: flex;
  width: 800px;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}

.tabLineContainer {
  width: 800px;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}
