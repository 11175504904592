
.BarChartTitle {
  font-size: 24px;
  color: #1F1F1F;
}

.autoSelectTitle {
  font-size: 14px;
  color: #494949;
  margin-top: 24px;
  margin-bottom: 15px;
}

.checkboxContainerBox {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.checkboxCustomLabel {
  font-size: 14px;
  color: #494949;
  cursor: pointer;
  margin-left: -10px;
}

.XYDiagram {
  width: 840px;
  height: 520px;
  display: flex;
  margin-bottom: 80px;
}

.XAxis {
  width: 40px;
  height: 530px;
  display: flex;
  flex-direction: column;
}

.XAxisItem {
  font-size: 18px;
  color: #9C9C9C;
  width: 40px;
  padding-right: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.XYDiagramInner {
  width: 800px;
  height: 530px;
  position: relative;
}

.XYDiagramBackground {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  width: 774px;
}

.XYDiagramBackgroundItem {
  width: 127px;
  height: 50px;
  border: 1px solid #DED6C7;
  border-collapse: collapse;
}


.YAxis {
  width: 774px;
  display: flex;
}

.YAxisItem {
  width: 129px;
  color: #61849D;
  font-weight: 700;
  font-size: 14px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.mainXYDiagram {
  height: 520px;
  width: 774px;
  border-left: 4px solid #61849D;
  border-bottom: 4px solid #61849D;
  z-index: 3;
  overflow: hidden;
}

.resultDot {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #61849D;
  position: absolute;
  z-index: 3;
  transform: scale(1);
  transition: transform 0.25s ease-in-out;
  cursor: pointer;

}

.resultLineDot {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #979797;
  position: absolute;
  z-index: 2;
}

.resultDot:hover {
  transform: scale(1.2);
  transition: transform 0.25s ease-in-out;
}

.overallImprovementXY {
  width: 128px;
  text-align: center;
  height: 64px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 30px;
}

.resultInfo {
  background-color: white;
  border: 1px solid #61849D;
  z-index: 9 !important;
  position: absolute;
  text-align: center;
  height: 90px;
  width: 120px;
  padding-top: 5px;
}