.loader_wrapper{
    display: flex;
    justify-content: center;
    padding: 10px;
}

.loader_color{
}

.loader {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;

    border: 10px solid #f3f3f3;
    border-top-color: rgb(97, 132, 157);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
