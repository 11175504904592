.mainProfile {
  margin-top: 80px;
  width: 1000px;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}

.sectionChange {
  margin-top: 50px;
}

.sectionChangePlans {
  margin-top: 100px;
}

.textInputContainer {
  margin-top: 40px;
}

.buttonContainer {
  width: 192px;
  height: 32px;
}

.divider {
  height: 20px;
  width: 100%;
}

.uploadContainer {
  display: flex;
  margin-top: 50px;
}

.uploadText {
  font-size: 16px;
  color: #000000;
  width: 287px;
  margin-left: 43px;
  margin-top: 41px;
}

.uploadButtonContainer {
  width: 192px;
  height: 32px;
  margin-top: 26px;
}

.colorSchemeContainer {
  margin-top: 56px;
}

.businessPlanContainer {
  margin-top: 56px;
  width: 576px;
  min-height: 319px;
}

.forMyBusinessPlan {
  display: flex;
  justify-content: space-between;
}

.additionalPlanText {
  margin-top: 71px;
  width: 332px;
  display: flex;
}

.additionalPlanTextOnly {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 21px;
  margin-left: 17px;
}

.additionalPlanIcon {
  width: 23.63px;
  height: 23.63px;
}

.additionalPlanButtonContainer {
  width: 332px;
  height: 40px;
}

.additionalPlanButtonContainerFirst {
  margin-top: 40px;
  margin-bottom: 17px;
}

.PlansContainer {
  margin-top: 42px;
  display: flex;
}

.deleteWarning {
  font-size: 18px;
  font-weight: 500;
  color: black;
  margin-top: 24px;
}

.deleteItems {
  margin-top: 17px;
  color: #000000;
  font-size: 15px;
  font-weight: 100;
}

.deleteItem {
  margin-bottom: 9px;
}

.bottomSection {
  margin-bottom: 200px;
}

.inputRow {
  display: flex;

  @media only screen and (max-width: 1075px) and (min-width: 993px) {
    flex-wrap: wrap;
  }
}

.bigTextInput {
  width: 800px;

  @media only screen and (max-width: 1075px) and (min-width: 993px) {
    width: 100% !important;
  }
}

.smallTextInput {
  width: 388px;

  @media only screen and (max-width: 1075px) and (min-width: 993px) {
    width: 100% !important;
  }
}

.smallTextInput:nth-child(2n) {
  margin-left: 24px;

  @media only screen and (max-width: 1075px) and (min-width: 993px) {
    margin-left: 0 !important;
  }
}
