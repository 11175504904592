.Search {
  height: 152px;
  width: 100%;
  position: relative;
}

.searchWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    gap: 20px;
    align-items: baseline;

    .searchInputWrapper {
      width: 100%;

      .SearchInput {
        width: 100% !important;
      }
    }
  }

  .generateSurveyContainer {
    margin-top: 64px;
    width: auto;
    height: 32px;
    margin-right: 16px;

    @media only screen and (max-width: 992px) {
      margin-top: 0;
    }

    .buttonText {
      font-size: 14px;

      cursor: pointer;
      padding: 0 10px !important;
    }
  }
}

.SearchInput {
  border-radius: 24px;
  margin-top: 64px;
  background-color: #f4f4f3;
  height: 48px;
  width: 424px;
  color: #9c9c9c;
  font-size: 14px;
  line-height: 16px;
  padding-left: 68px;
  border: 1px solid #f4f4f3;
  z-index: 1;
}

.ActiveSearch {
  background-color: white;
  border: 1px solid #61849d;
  color: black;
}

.searchIcon {
  position: absolute;
  top: 80px;
  left: 25px;
  z-index: 2;
}

.searchContainer {
  position: relative;
  z-index: 1;
}

.SearchInput:before {
  border-bottom: none !important;
}

.SearchInput:after {
  border-bottom: none !important;
}

.optionsContainer {
  border: 1px solid #61849d;
  position: absolute;
  width: 424px;
  border-radius: 24px;
  margin-top: 8px;
  background-color: white;
  z-index: 3;
  overflow: hidden;

  @media only screen and (max-width: 992px) {
    width: 100%;
    padding-bottom: 10px;
  }
}

.specificTitle {
  color: #9c9c9c;
  font-size: 10px;
  padding-left: 35px;
}

.searchOption {
  width: calc(100% - 53px);
  color: #494949;
  font-size: 14px;
  padding-left: 23px;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 12px;
}

.searchOption:hover {
  background-color: #ecfaff;
}

.specificOptionsController {
  margin-top: 8px;
}
