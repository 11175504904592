.BarChart {
  position: relative;
  width: 864px;
  height: 380px;
}

.BarChartContainer {
  background-color: #CED9E0;
  width: 728px;
  height: 32px;
  position: absolute;
  top: 175px;
}

.BarChartItem {
  background-color: #61849D;
  height: 100%;
  position: absolute;
  top: 0;
}

.BarChartLegend {
  width: 728px;
  height: 350px;
  position: absolute;
  overflow: visible;
}

.BarChartLegendItem {
  position: absolute;
  border-right: 2px solid #9C9C9C;
  z-index: 3;
  left: 0;
}

.BarChartLegendItem:hover{
  border-right-color: #494949;
  .BarChartLegendValue {
    background-color: white;
    border: 1px solid #61849D;
    z-index: 9 !important;
  }
}

.BarChartLegendItem:nth-child(1) {
  z-index: 7;
  .BarChartLegendValue {
    top: -60px;
  }
}

.BarChartLegendItem:nth-child(2) {
  z-index: 6;
  .BarChartLegendValue {
    bottom: -60px;
  }
}
.BarChartLegendItem:nth-child(3) {
  z-index: 5;
  .BarChartLegendValue {
    top: -60px;
  }
}

.BarChartLegendItem:nth-child(4) {
  z-index: 4;
  .BarChartLegendValue {
    bottom: -60px;
  }
}

.BarChartLegendItem:nth-child(5) {
  z-index: 3;
  .BarChartLegendValue {
    top: -60px;
  }
}

.BarChartLegendItem:nth-child(6) {
  z-index: 2;
  .BarChartLegendValue {
    bottom: -60px;
  }
}

.BarChartLegendValue {
  width: 124px;
  height: 54px;
  position: absolute;
  text-align: center;
  right: -62px;
}

.percentageOnly {
  color: #1F1F1F;
  font-size: 18px;
}

.valueTitle {
  color: #61849D;
  font-size: 14px;
}

.valueSubtitle {
  color: #494949;
  font-size: 12px;
}

.overallImprovement {
  position: absolute !important;
  right: 0;
  width: 128px;
  text-align: center;
  height: 64px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.overallImprovementNumber {
  color: #61849D;
  font-size: 24px;
}

.overallImprovementText {
  color: #494949;
  font-size: 14px;
}

.BarChartTitle {
  font-size: 24px;
  color: #1F1F1F;
}

.autoSelectTitle {
  font-size: 14px;
  color: #494949;
  margin-top: 24px;
  margin-bottom: 15px;
}

.checkboxContainerBox {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.checkboxCustomLabel {
  font-size: 14px;
  color: #494949;
  cursor: pointer;
  margin-left: -10px;
}
